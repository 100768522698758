export const cryptoKey = 'M!91*g8gy8zs';

export const SUPPORT_EMAIL_ID = 'support@vetvalue.pet';

export const COMMON_REGEX = {
    WEBSITE:
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[A-Za-z0-9]+([\-\.]{1}[A-Za-z0-9]+)*\.[A-Za-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    NAME: /^([ \t])*[a-zA-Z.'-]+(?:[ \t]*[a-zA-Z.'-]+)*([ \t])*$/,
    EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+(?<!\.)$/,
    PASSWORD: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{6,64})/g,
    STRONG_PASSWORD:
        /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$\^*.[\]{}()?"!@#%&\/\\,><':;|_~`=+\- ])[A-Za-z0-9$\^*.[\]{}()?"!@#%&\/\\,><':;|_~`=+\- ]{6,256}$/,
    ZIP_CODE: /^[0-9]{5}$/,
    PHONE_NUMBER: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
};

export const RESOURCE_TYPES = {
    WHITE_PAPER: 1,
    SAMPLE: 2,
    CHART: 3,
};

export const PRACTICE_STATUS = {
    1: 'Approved',
    2: 'Pending',
    3: 'Not Approved',
};

export const PRACTICE_STATUS_ENUM = {
    Approved: 1,
    Pending: 2,
    Rejected: 3,
};

export const USER_STATUS = {
    1: 'Joined',
    2: 'Requested',
};

export const USER_ROLE_NAMES = {
    1: 'Practice Owner',
    2: 'Admin',
    3: 'Secondary User',
    4: 'Referral Partner',
    5: 'User',
    6: 'Nonprofit Admin',
};

export const W9_Document_Status = {
    INREVIEW: 0,
    ACCEPTED: 1,
    REJECTED: 2,
};

export const IS_PERMISSION_AVAILABLE = {
    AVAILABLE: 1,
    NOT_AVAILABLE: 2,
};

export const USER_ROLES = {
    PRACTICE_OWNER: 1,
    ADMIN: 2,
    PRACTICE_SECONDARY_USER: 3,
    REFERRAL_USER: 4,
    USER: 5,
    CHARITY_ADMIN: 6,
};

export const USER_POC_ACCESS_RIGHTS = {
    UNRESTRICTED_ACCESS: 0,
    VIEW_ONLY: 1,
    NO_ACCESS: 2,
};

export const AssociateMemberForNonprofitStatus = {
    PENDING: 0,
    APPROVED: 1,
    REJECTED: 2
}

export const PERMISSIONS = {
    VIEW_CONTROLS: 'VIEW_CONTROLS',
    EDIT_CONTROLS: 'EDIT_CONTROLS',
    UPLOAD_CHART: 'UPLOAD_CHART',
    UPLOAD_WHITEPAPER: 'UPLOAD_WHITEPAPER',
    GENERATE_F_E: 'GENERATE_F_E',
    VIEW_PAID_REPORT: 'VIEW_PAID_REPORT',
    GENERATE_PAID_REPORT: 'GENERATE_PAID_REPORT',
    VIEW_F_E: 'VIEW_F_E',
    VIEW_SAMPLE_REPORTS: 'VIEW_SAMPLE_REPORTS',
    VIEW_WHITE_PAPERS: 'VIEW_WHITE_PAPERS',
    SAVE_PAID_REPORT_DRAFT: 'SAVE_PAID_REPORT_DRAFT',
    PURCHASE_ITEM: 'PURCHASE_ITEM',
};

export const REPORT_TYPE = {
    FREE: 1,
    DETAILED: 2,
    APVAR: 4,
};

export const VERIFICATION_STATUS = {
    NOT_VERIFIED: 0,
    VERIFIED: 1,
    PREVIOUSLY_VERIFIED: 2,
};

export const BENEFIT_CODES = {
    FIRST_PAID_REPORT_DISCOUNT: 1001,
    REFERRER_BONUS: 1002,
    MNA_BONUS: 1003,
    PAID_REPORT_RATE: 1004,
    APVAR_PAID_REPORT_RATE: 1005,
};

export const FREEMIUM_FOOTER_TEXT_1 = `This information enables us to better understand some basic information about you and your practice.
VetValue and its affiliates will never share any information identified with you or your practice to 3rd parties.`;

export const FREEMIUM_FOOTER_TEXT_2 = `Why are we asking you this? Fair question. This information allows us a)
to verify that you are a veterinary practice owner using our engine for the reason we created it - to help independent practice owners
better understand their practice valuations; and / or b) to collect the inputs for our valuation algorithm.
We will take good care of any information you give us and we will never share your contact information and identified practice detail to unaffiliated 3rd parties.`;

export const INITIAL_FORM_STATE = {
    PracticeName: '',
    PracticeWebsite: '',
    PrimaryPracticeOwnerName: '',
    PrimaryPracticeOwnerBirthYear: null,
    NoOfOwners: 1,
    ZipCode: null,
    LegalStructure: 3,
    TaxTreatment: 2,
    CurrentPracticeDebt: 0,
    YearOfPracticeFormation: null,
    PracticeType: {
        PTFormat: null,
        ServiceMix: {
            CA_GP: 0,
            CA_S: 0,
            CA_E: 0,
            Equine: 0,
            LA: 0,
            BoardingGrooming: 0,
            Others: 0,
        },
    },
    PracticeLocations: {
        NoOfLocations: 0,
        Locations: [
            {
                ZipCode: null,
                TenureInLocation: 0,
                RevPerLocation: 0,
                SqFootage: 0,
                RenovationYear: null,
            },
        ],
    },
    LTMPeriodEnd:
        new Date().getFullYear().toString() +
        '-' +
        (new Date().getMonth() + 1).toString() +
        '-' +
        new Date().getDate().toString(),
    Revenue: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    DirectCosts: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    OwnerCompensation: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    AssociateDVMCompensation: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    ReliefDVMCompensation: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    SupportStaffCompensation: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    OtherLaborCosts: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    EmployeeFringeBenefits: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    PayrollTaxes: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    WorkersCompensation: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    MerchantFeeIncomeCollectionCosts: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    RentExpense: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    FacilityEquipmentExpense: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    AdvertisingMarketing: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    AdministrativeCosts: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    NoofInvoices: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    NoofPatientVisists: {
        LTM: 0,
        LTM1: 0,
        YTD: 0,
        PrevYTD: 0,
        Commentary: '',
    },
    Doctor: [
        {
            ClinicianCode: 'Doctor1',
            FT_PT: null,
            ServiceMixType: null,
            IsEmployedByYou: null,
            DateOfJoining: '',
            DateOfDeparture: '',
            HoursPerWeeks: 0,
        },
    ],
    ActivePatients: {
        NoofActivePatients: 0,
        Commentary: '',
    },
    EnactedFeeIncrease: {
        YTD: 0,
        LTM: 0,
    },
    ClinicalSupportStaff: {
        NoofClinicalSupportStaff: {
            LTM: 0,
            LTM1: 0,
            LTM2: 0,
        },
        NoofBoardingGrooming: {
            LTM: 0,
            LTM1: 0,
            LTM2: 0,
        },
        Commentary: '',
    },
    NonClinicalSupportStaff: {
        NoofCustomerSupportStaff: {
            LTM: 0,
            LTM1: 0,
            LTM2: 0,
        },
        NoofOtherNonClinicalSupportStaff: {
            LTM: 0,
            LTM1: 0,
            LTM2: 0,
        },
        Commentary: '',
    },
    CorpoateAdjustmentsPersonalExpenses: {
        Insurance: {
            LTM: 0,
            LTM1: 0,
            YTD: 0,
            PrevYTD: 0,
            Commentary: '',
        },
        Automobile: {
            LTM: 0,
            LTM1: 0,
            YTD: 0,
            PrevYTD: 0,
            Commentary: '',
        },
        TE: {
            LTM: 0,
            LTM1: 0,
            YTD: 0,
            PrevYTD: 0,
            Commentary: '',
        },
        OtherOwnerExpenses: {
            LTM: 0,
            LTM1: 0,
            YTD: 0,
            PrevYTD: 0,
            Commentary: '',
        },
    },
    OwnersClinicalCompensation: {
        ClinicianOwnersProduction: {
            LTM: 0,
            LTM1: 0,
            YTD: 0,
            PrevYTD: 0,
            Commentary: '',
        },
        IsOwnerFullCompensation: 1,
        OwnersClinicalSalary: {
            LTM: 0,
            LTM1: 0,
            YTD: 0,
            PrevYTD: 0,
            Commentary: '',
        },
        OwnersSalaryToMgmt: {
            LTM: 0,
            LTM1: 0,
            YTD: 0,
            PrevYTD: 0,
            Commentary: '',
        },
    },
    OwnersManagementCompensation: {
        HasPracticeManager: 1,
        PracticeManagerSalary: {
            LTM: 0,
            LTM1: 0,
            YTD: 0,
            PrevYTD: 0,
            Commentary: '',
        },
        YourActivityPercentage: 0,
    },
    CorporateAdjustmentsRentExpenses: {
        OwnRealEstate: 2,
        EstimateMarketRent: 0,
        IsConsistentWithMarket: 1,
        NoEstimateRent: 0,
    },
    CashAccrualAdjustmentsDrugVaccine: {
        IsExpenseReflectMedicine: 1,
        DrugVaccinePurchase: {
            LTM: 0,
            LTM1: 0,
            YTD: 0,
            PrevYTD: 0,
            Commentary: '',
        },
    },
    CashAccrualAdjustmentsOther: {
        LstExpenseItem: [
            {
                ExpenseItem: '',
                LTM: 0,
                LTM1: 0,
                YTD: 0,
                Prev_YTD: 0,
                ExpectedUsefulLife: 0,
            },
        ],
    },
    OneOffItemsCapitalInvestments: {
        LstExpenseItem: [
            {
                ExpenseItem: '',
                LTM: 0,
                LTM1: 0,
                YTD: 0,
                Prev_YTD: 0,
                ExpectedUsefulLife: 0,
            },
        ],
    },
    OneOffItemsOther: {
        LstExpenseItem: [
            {
                ExpenseItem: '',
                LTM: 0,
                LTM1: 0,
                YTD: 0,
                Prev_YTD: 0,
                ExpectedUsefulLife: 0,
            },
        ],
    },
};

export const DEFAULT_CORP_ADJUSTMENT_RENT_EXPENSE_STATE_STRUCTURE = {
    OwnRealEstate: 2,
    EstimateMarketRent: 0,
    IsConsistentWithMarket: 1,
    NoEstimateRent: 0,
};

export const DEFAULT_DOCTOR_STRUCTURE = {
    value: [
        {
            value: `Doctor #{{nextIndex}}`,
            type: 'label',
        },
        {
            label: 'Full or Part-Time',
            value: 1,
            options: [
                { text: 'Full Time', value: 1 },
                { text: 'Part Time', value: 2 },
            ],
            k: `Doctor-{{index}}-FT_PT`,
            type: 'dropdownList',
            placeholder: '',
            infoText: '',
            validations: { required: { value: true } },
            onChange: '',
        },
        {
            label: 'Type',
            value: null,
            options: [
                { text: 'Companion Animal - GP', value: 1 },
                { text: 'Companion Animal - Specialist', value: 2 },
                { text: 'Companion Animal - Emergency', value: 3 },
                { text: 'Equine', value: 4 },
                { text: 'Large Animal', value: 5 },
                { text: 'Boarding / Grooming', value: 6 },
                { text: 'Other', value: 7 },
            ],
            k: `Doctor-{{index}}-ServiceMixType`,
            type: 'dropdownList',
            placeholder: '',
            infoText: '',
            validations: { required: { value: true } },
            onChange: '',
            grow: 2,
        },
        {
            label: 'Currently Employed by You',
            value: 1,
            options: [
                { text: 'Yes', value: 1 },
                { text: 'No', value: 2 },
            ],
            k: `Doctor-{{index}}-IsEmployedByYou`,
            type: 'dropdownList',
            placeholder: '',
            infoText: '',
            validations: { required: { value: true } },
            onChange: '',
        },
        {
            label: 'Date Joined',
            value: null,
            k: `Doctor-{{index}}-DateOfJoining`,
            type: 'date',
            placeholder: '',
            infoText: '',
            validations: { required: { value: false } },
            onChange: '',
        },
        {
            label: 'Date Departed',
            value: null,
            k: `Doctor-{{index}}-DateOfDeparture`,
            type: 'date',
            placeholder: '',
            infoText: '',
            validations: { required: { value: false } },
            onChange: '',
        },
        {
            label: 'Hours per Week',
            value: 0,
            k: `Doctor-{{index}}-HoursPerWeeks`,
            type: 'number',
            placeholder: '',
            infoText: '',
            validations: {
                required: { value: false },
                max: { value: 999 },
                min: { value: 0 },
            },
            onChange: '',
            grow: 0.5,
        },
        {
            label: '',
            value: null,
            k: `Doctor-{{index}}-Remove`,
            type: 'remove',
            grow: 0.2,
        },
    ],
    type: 'array',
};

export const DEFAULT_CASH_ACCRUAL_ADJUSTMENTS_OTHER_STRUCTURE = {
    value: [
        {
            value: `Item {{nextIndex}}`,
            type: 'label',
        },
        {
            label: 'Expense Item',
            value: '',
            k: `CashAccrualAdjustmentsOther-LstExpenseItem-{{index}}-ExpenseItem`,
            type: 'text',
            placeholder: '',
            infoText: '',
            validations: { required: { value: false } },
            onChange: '',
        },
        {
            label: '{{ltm}}',
            isDynamicLtm: true,
            value: 0,
            k: `CashAccrualAdjustmentsOther-LstExpenseItem-{{index}}-LTM`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
        },
        {
            label: '{{ltm1}}',
            isDynamicLtm1: true,
            value: 0,
            k: `CashAccrualAdjustmentsOther-LstExpenseItem-{{index}}-LTM1`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
        },
        {
            label: 'YTD',
            value: 0,
            k: `CashAccrualAdjustmentsOther-LstExpenseItem-{{index}}-YTD`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
        },
        {
            label: 'Previous Year YTD',
            value: 0,
            k: `CashAccrualAdjustmentsOther-LstExpenseItem-{{index}}-Prev_YTD`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
            isDisabled: false,
        },
        {
            label: 'Expected Useful Life',
            value: 0,
            k: `CashAccrualAdjustmentsOther-LstExpenseItem-{{index}}-ExpectedUsefulLife`,
            type: 'number',
            placeholder: '',
            infoText: '',
            validations: { required: { value: false } },
            onChange: '',
        },
        {
            label: '',
            value: null,
            k: `CashAccrualAdjustmentsOther-LstExpenseItem-{{index}}-Remove`,
            type: 'remove',
            grow: 0.2,
        },
    ],
    type: 'array',
};

export const DEFAULT_ONE_OFF_CAPITAL_INVESTMENTS_STRUCTURE = {
    value: [
        {
            value: `Item {{nextIndex}}`,
            type: 'label',
        },
        {
            label: 'Expense Item',
            value: '',
            k: `OneOffItemsCapitalInvestments-LstExpenseItem-{{index}}-ExpenseItem`,
            type: 'text',
            placeholder: '',
            infoText: '',
            validations: { required: { value: false } },
            onChange: '',
        },
        {
            label: '{{ltm}}',
            isDynamicLtm: true,
            value: 0,
            k: `OneOffItemsCapitalInvestments-LstExpenseItem-{{index}}-LTM`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
        },
        {
            label: '{{ltm1}}',
            isDynamicLtm1: true,
            value: 0,
            k: `OneOffItemsCapitalInvestments-LstExpenseItem-{{index}}-LTM1`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
        },
        {
            label: 'YTD',
            value: 0,
            k: `OneOffItemsCapitalInvestments-LstExpenseItem-{{index}}-YTD`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
        },
        {
            label: 'Previous Year YTD',
            value: 0,
            k: `OneOffItemsCapitalInvestments-LstExpenseItem-{{index}}-Prev_YTD`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
            isDisabled: false,
        },
        {
            label: '',
            value: null,
            k: `OneOffItemsCapitalInvestments-LstExpenseItem-{{index}}-Remove`,
            type: 'remove',
            grow: 0.2,
        },
    ],
    type: 'array',
};

export const DEFAULT_ONE_OFF_ITEMS_OTHERS_STRUCTURE = {
    value: [
        {
            value: `Item {{nextIndex}}`,
            type: 'label',
        },
        {
            label: 'Expense Item',
            value: '',
            k: `OneOffItemsOther-LstExpenseItem-{{index}}-ExpenseItem`,
            type: 'text',
            placeholder: '',
            infoText: '',
            validations: { required: { value: false } },
            onChange: '',
        },
        {
            label: '{{ltm}}',
            isDynamicLtm: true,
            value: 0,
            k: `OneOffItemsOther-LstExpenseItem-{{index}}-LTM`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
        },
        {
            label: '{{ltm1}}',
            isDynamicLtm1: true,
            value: 0,
            k: `OneOffItemsOther-LstExpenseItem-{{index}}-LTM1`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
        },
        {
            label: 'YTD',
            value: 0,
            k: `OneOffItemsOther-LstExpenseItem-{{index}}-YTD`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
        },
        {
            label: 'Previous Year YTD',
            value: 0,
            k: `OneOffItemsOther-LstExpenseItem-{{index}}-Prev_YTD`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: false,
                },
            },
            onChange: '',
            isDisabled: false,
        },
        {
            label: '',
            value: null,
            k: `OneOffItemsOther-LstExpenseItem-{{index}}-Remove`,
            type: 'remove',
            grow: 0.2,
        },
    ],
    type: 'array',
};

export const DEFAULT_LOCATION_STRUCTURE = {
    value: [
        {
            value: `Location {{nextIndex}}`,
            type: 'label',
        },
        {
            label: 'ZipCode',
            value: null,
            k: `PracticeLocations-Locations-{{index}}-ZipCode`,
            type: 'zipcode',
            placeholder: '',
            infoText: '',
            validations: {
                required: { value: true },
                pattern: {
                    value: '^[0-9]{5}$',
                    errorMessage: 'Zipcode must be 5 digits',
                },
            },
            onChange: '',
        },
        {
            label: 'Practice Tenure in Location (# of yrs)',
            value: 0,
            k: `PracticeLocations-Locations-{{index}}-TenureInLocation`,
            type: 'number',
            placeholder: '',
            infoText: '',
            validations: { required: { value: true } },
            onChange: '',
        },
        {
            label: 'Annual Rev per Location (Approx) ($s)',
            value: 0,
            k: `PracticeLocations-Locations-{{index}}-RevPerLocation`,
            type: 'currency',
            placeholder: '',
            infoText: '',
            validations: {
                required: {
                    value: true,
                },
            },
            onChange: '',
        },
        {
            label: 'Sq Footage',
            value: 0,
            k: `PracticeLocations-Locations-{{index}}-SqFootage`,
            type: 'number',
            placeholder: '',
            infoText: '',
            validations: { required: { value: true } },
            onChange: '',
        },
        {
            label: 'When was most recent substantial renovation to clinical and waiting room space?',
            value: new Date().getFullYear(),
            k: `PracticeLocations-Locations-{{index}}-RenovationYear`,
            type: 'year',
            placeholder: '',
            infoText:
                'Enter the year when during which you<br/> incurred the majority of expenses for your<br/> most recent renovation',
            validations: {
                required: true,
                max: {
                    value: new Date().getFullYear(),
                    errorMessage: 'Value cannot be greater than current year',
                },
                min: {
                    value: 1900,
                    errorMessage: 'Value cannot be smaller than 1900 year',
                },
            },
            onChange: '',
        },
    ],
    type: 'array',
};

export const DEFAULT_DOCTOR_STATE = {
    ClinicianCode: `Doctor{{index}}`,
    FT_PT: 1,
    ServiceMixType: 1,
    IsEmployedByYou: true,
    DateOfJoining: null,
    DateOfDeparture: null,
    HoursPerWeeks: 0,
};

export const DEFAULT_CASH_ACCRUAL_ADJUSTMENTS_OTHER_STATE = {
    ExpenseItem: '',
    LTM: 0,
    LTM1: 0,
    YTD: 0,
    Prev_YTD: 0,
    ExpectedUsefulLife: 0,
};

export const DEFAULT_ONE_OFF_CAPITAL_INVESTMENTS_STATE = {
    ExpenseItem: '',
    LTM: 0,
    LTM1: 0,
    YTD: 0,
    Prev_YTD: 0,
    ExpectedUsefulLife: 0,
};

export const DEFAULT_ONE_OFF_ITEMS_OTHER_STATE = {
    ExpenseItem: '',
    LTM: 0,
    LTM1: 0,
    YTD: 0,
    Prev_YTD: 0,
    ExpectedUsefulLife: 0,
};

export const DEFAULT_PRACTICE_LOCATIONS_STATE = {
    ZipCode: '',
    TenureInLocation: 0,
    RevPerLocation: 0,
    SqFootage: 0,
    RenovationYear: new Date().getFullYear(),
};

export const DEFAULT_STRUCTURES_MAPPINGS = {
    Locations: DEFAULT_LOCATION_STRUCTURE,
    Doctor: DEFAULT_DOCTOR_STRUCTURE,
    CashAccrualAdjustmentsOther:
        DEFAULT_CASH_ACCRUAL_ADJUSTMENTS_OTHER_STRUCTURE,
    OneOffItemsCapitalInvestments:
        DEFAULT_ONE_OFF_CAPITAL_INVESTMENTS_STRUCTURE,
    OneOffItemsOther: DEFAULT_ONE_OFF_ITEMS_OTHERS_STRUCTURE,
};

export const PRACTICE_DESCRIPTION = [
    {
        sectionHeader: 'Details',
        fields: [
            {
                label: "1. Owner's Email ID",
                value: '',
                placeholder: '',
                isDisabled: true,
                infoText: '',
                k: 'practiceEmail',
                type: 'text',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '2. Do you practice medicine?',
                value: '',
                placeholder: '',
                isDisabled: true,
                infoText: '',
                k: 'practiceMedicine',
                type: 'text',
                onChange: '',
                cssClass: 'input-width',
            },
        ],
    },
    {
        sectionHeader: 'Section 1',
        fields: [
            {
                label: '1. Practice Name',
                value: '',
                placeholder: "Enter your practice's name",
                infoText: "Enter your practice's name",
                validations: {
                    required: { value: true },
                    pattern: {
                        value: '/[a-zA-z0-9]{1,255}/g',
                        errorMessage: 'Max 255 characters',
                    },
                },
                k: 'PracticeName',
                type: 'text',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '2. Practice URL Website',
                value: '',
                placeholder: 'Enter website address',
                infoText: '',
                validations: {
                    required: { value: true },
                    pattern: {
                        value: '/[a-zA-z0-9]{1,255}/g',
                        errorMessage: 'Max 255 characters',
                    },
                },
                k: 'PracticeWebsite',
                type: 'website',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '3. Primary Practice Owner Name',
                value: '',
                placeholder: "Enter Primary Owner's name",
                infoText: '',
                validations: {
                    required: { value: true },
                    pattern: {
                        value: '/[a-zA-z0-9]{1,100}/g',
                        errorMessage: 'Max 100 characters',
                    },
                },
                k: 'PrimaryPracticeOwnerName',
                type: 'text',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '4. Primary Practice Owner Birth Year',
                value: null,
                placeholder: 'Enter birth year',
                infoText: '',
                validations: {
                    required: { value: true },
                    max: {
                        value: new Date().getFullYear(),
                        errorMessage:
                            'Value cannot be greater than current year',
                    },
                    min: {
                        value: new Date().getFullYear() - 100,
                        errorMessage: `Value cannot be smaller than ${
                            new Date().getFullYear() - 100
                        } year`,
                    },
                },
                k: 'PrimaryPracticeOwnerBirthYear',
                type: 'year',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '5. Number of Owners',
                value: 1,
                placeholder: 'Enter number of owners',
                infoText: '',
                validations: {
                    required: { value: true },
                    min: { value: 1, errorMessage: 'Minimum value is 1' },
                    max: { value: 100, errorMessage: 'Max value is 100' },
                },
                k: 'NoOfOwners',
                type: 'number',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '6. Practice Address',
                value: '',
                placeholder: 'Enter legal address',
                infoText: '',
                validations: {
                    required: { value: true },
                    pattern: {
                        value: '/[a-zA-z0-9]{1,255}/g',
                        errorMessage: 'Max 255 characters',
                    },
                },
                k: 'PracticeAddress',
                type: 'text',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '7. Practice City',
                value: '',
                placeholder: 'Enter city',
                infoText: '',
                validations: {
                    required: { value: true },
                    pattern: {
                        value: '/[a-zA-z0-9]{1,100}/g',
                        errorMessage: 'Max 100 characters',
                    },
                },
                k: 'PracticeCity',
                type: 'text',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '8. Practice State',
                value: null,
                options: [],
                placeholder: '',
                infoText: '',
                validations: { required: { value: true } },
                k: 'PracticeState',
                type: 'dropdownList',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '9. Zip Code',
                value: null,
                placeholder: 'Enter zip code',
                infoText: '',
                validations: {
                    required: { value: true },
                    pattern: {
                        value: '^[0-9]{5}$',
                        errorMessage: 'Zipcode must be 5 digits',
                    },
                },
                k: 'ZipCode',
                type: 'zipcode',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '10. Legal Structure',
                value: null,
                options: [
                    { text: 'C-Corp', value: 1 },
                    { text: 'S-Corp', value: 2 },
                    { text: 'LLC', value: 3 },
                    { text: 'Partnership', value: 4 },
                    { text: 'Professional Corporation', value: 5 },
                ],
                placeholder: '',
                infoText: '',
                validations: {
                    required: { value: false },
                },
                k: 'LegalStructure',
                type: 'dropdownList',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '11. Tax Treatment',
                value: null,
                options: [
                    { text: 'Corp (entity-level taxation)', value: 1 },
                    { text: 'Partnership (pass-through taxation)', value: 2 },
                ],
                placeholder: '',
                infoText: '',
                validations: {
                    required: { value: false },
                },
                k: 'TaxTreatment',
                type: 'dropdownList',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '12. Current Practice Debt',
                value: 0,
                placeholder: 'Enter practice debt',
                infoText:
                    'Enter your current practice debt. <u>Exclude</u> all<br /> mortgage debt associated with your building,<br /> or land',
                validations: {
                    required: {
                        value: false,
                    },
                },
                k: 'CurrentPracticeDebt',
                type: 'currency',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '13. Year of Practice Formation',
                value: null,
                placeholder: 'Enter formation year eg. 2012',
                infoText: '',
                validations: {
                    required: { value: true },
                    max: {
                        value: new Date().getFullYear(),
                        errorMessage:
                            'Value cannot be greater than current year',
                    },
                    min: {
                        value: 1900,
                        errorMessage: 'Value cannot be smaller than 1900 year',
                    },
                },
                k: 'YearOfPracticeFormation',
                type: 'year',
                onChange: '',
                cssClass: 'input-width',
            },
            {
                label: '14. Practice Group Affiliation',
                value: null,
                options: [],
                placeholder: '',
                infoText: '',
                validations: { required: { value: true } },
                k: 'PracticeGroupAffiliation',
                type: 'dropdownList',
                onChange: '',
                cssClass: 'input-width',
            },
        ],
    },
    {
        sectionHeader: 'Section Clinical',
        fields: [
            {
                label: '15. Practice Type',
                value: [
                    {
                        label: 'a. Format',
                        value: null,
                        options: [
                            { text: 'Facility Based', value: 1 },
                            { text: 'Mobile', value: 2 },
                        ],
                        placeholder: '',
                        infoText: '',
                        validations: {
                            required: { value: true },
                        },
                        k: 'PracticeType-PTFormat',
                        type: 'dropdownList',
                        onChange: '',
                    },
                    {
                        label: 'b. Service Mix and Patient Type',
                        value: {
                            header: 'Clinical Service:',
                            columns: [
                                {
                                    label: 'Clinical Service:',
                                },
                                {
                                    label: 'Est. % of Practice Revenue (In percents.“100” = 100%)',
                                    infoText:
                                        "Enter in decimals representing %, (i.e. 10% entered as '.1') ",
                                },
                            ],
                            rows: [
                                {
                                    value: [
                                        {
                                            value: 'Companion Animal - General Practice',
                                            type: 'label',
                                        },
                                        {
                                            label: 'Est. % of Practice Revenue',
                                            value: 0,
                                            k: 'PracticeType-ServiceMix-CA_GP',
                                            type: 'percentage',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: false },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        'Minimum value is 0',
                                                },
                                                max: {
                                                    value: 100,
                                                    errorMessage:
                                                        'Max value is 100',
                                                },
                                            },
                                            onChange: '',
                                        },
                                    ],
                                    type: 'array',
                                },
                                {
                                    value: [
                                        {
                                            value: 'Companion Animal - Specialist',
                                            type: 'label',
                                        },
                                        {
                                            label: 'Est. % of Practice Revenue',
                                            value: 0,
                                            k: 'PracticeType-ServiceMix-CA_S',
                                            type: 'percentage',
                                            placeholder: '0',
                                            infoText: '',
                                            validations: {
                                                required: { value: false },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        'Minimum value is 0',
                                                },
                                                max: {
                                                    value: 100,
                                                    errorMessage:
                                                        'Max value is 100',
                                                },
                                            },
                                            onChange: '',
                                        },
                                    ],
                                    type: 'array',
                                },
                                {
                                    value: [
                                        {
                                            value: 'Companion Animal - Emergency',
                                            type: 'label',
                                        },
                                        {
                                            label: 'Est. % of Practice Revenue',
                                            value: 0,
                                            k: 'PracticeType-ServiceMix-CA_E',
                                            type: 'percentage',
                                            placeholder: '0.0',
                                            infoText: '',
                                            validations: {
                                                required: { value: false },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        'Minimum value is 0',
                                                },
                                                max: {
                                                    value: 100,
                                                    errorMessage:
                                                        'Max value is 100',
                                                },
                                            },
                                            onChange: '',
                                        },
                                    ],
                                    type: 'array',
                                },
                                {
                                    value: [
                                        {
                                            value: 'Equine',
                                            type: 'label',
                                        },
                                        {
                                            label: 'Est. % of Practice Revenue',
                                            value: 0,
                                            k: 'PracticeType-ServiceMix-Equine',
                                            type: 'percentage',
                                            placeholder: '0',
                                            infoText: '',
                                            validations: {
                                                required: { value: false },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        'Minimum value is 0',
                                                },
                                                max: {
                                                    value: 100,
                                                    errorMessage:
                                                        'Max value is 100',
                                                },
                                            },
                                            onChange: '',
                                        },
                                    ],
                                    type: 'array',
                                },
                                {
                                    value: [
                                        {
                                            value: 'Large Animal',
                                            type: 'label',
                                        },
                                        {
                                            label: 'Est. % of Practice Revenue',
                                            value: 0,
                                            k: 'PracticeType-ServiceMix-LA',
                                            type: 'percentage',
                                            placeholder: '0',
                                            infoText: '',
                                            validations: {
                                                required: { value: false },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        'Minimum value is 0',
                                                },
                                                max: {
                                                    value: 100,
                                                    errorMessage:
                                                        'Max value is 100',
                                                },
                                            },
                                            onChange: '',
                                        },
                                    ],
                                    type: 'array',
                                },
                                {
                                    value: [
                                        {
                                            value: 'Boarding / Grooming',
                                            type: 'label',
                                        },
                                        {
                                            label: 'Est. % of Practice Revenue',
                                            value: 0,
                                            k: 'PracticeType-ServiceMix-BoardingGrooming',
                                            type: 'percentage',
                                            placeholder: '0',
                                            infoText: '',
                                            validations: {
                                                required: { value: false },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        'Minimum value is 0',
                                                },
                                                max: {
                                                    value: 100,
                                                    errorMessage:
                                                        'Max value is 100',
                                                },
                                            },
                                            onChange: '',
                                        },
                                    ],
                                    type: 'array',
                                },
                                {
                                    value: [
                                        {
                                            value: 'Other',
                                            type: 'label',
                                        },
                                        {
                                            label: 'Est. % of Practice Revenue',
                                            value: 0,
                                            k: 'PracticeType-ServiceMix-Others',
                                            type: 'percentage',
                                            placeholder: '0',
                                            infoText: '',
                                            validations: {
                                                required: { value: false },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        'Minimum value is 0',
                                                },
                                                max: {
                                                    value: 100,
                                                    errorMessage:
                                                        'Max value is 100',
                                                },
                                            },
                                            onChange: '',
                                        },
                                    ],
                                    type: 'array',
                                },
                            ],
                        },
                        placeholder: '',
                        infoText:
                            'Does your practice offer the following services?  For each that your practice offers, enter a % of revenue (estimates are sufficient)',
                        validations: {},
                        k: 'PracticeType-ServiceMix',
                        type: 'table',
                        onChange: '',
                    },
                ],
                placeholder: '',
                infoText:
                    'Does your practice offer the following<br/> services?  For each that your practice offers,<br/> enter a % of revenue (estimates are sufficient)<br/> Estimated % of Practice Revenue (in Percent<br/> i.e. 10% entered as 10)',
                validations: {},
                k: 'PracticeType',
                type: 'array',
                onChange: '',
            },
        ],
    },
    {
        sectionHeader: 'Section Locations',
        fields: [
            {
                label: '16. Number of Locations',
                value: [
                    {
                        label: 'Number of practice locations',
                        value: 0,
                        placeholder: 'Enter number of practice locations',
                        infoText:
                            'Enter a whole number i.e. 1 for each location',
                        validations: {
                            required: { value: true },
                            min: {
                                value: 1,
                                errorMessage: 'Minimum value is 1',
                            },
                            max: {
                                value: 9999,
                                errorMessage: 'Max value is 9999',
                            },
                        },
                        k: 'PracticeLocations-NoOfLocations',
                        type: 'number',
                        onChange: '',
                    },
                    {
                        label: 'Location',
                        value: {
                            header: '',
                            columns: [
                                {
                                    label: '17. Location Grid',
                                },
                                {
                                    label: 'ZipCode',
                                    infoText: 'Location address',
                                },
                                {
                                    label: 'Practice Tenure in location (# of yrs)',
                                    infoText:
                                        'Enter # of Years, including fractional values i.e. 2.5',
                                },
                                {
                                    label: 'Annual Rev per Location (Approx) ($s)',
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place  ',
                                },
                                {
                                    label: 'Sq Footage',
                                    infoText:
                                        '# of square feet, no commas i.e. 1,000 entered as 1000',
                                },
                                {
                                    label: 'When was most recent substantial renovation to clinical and waiting room space?',
                                },
                            ],
                            rows: [],
                        },
                        toolbar: {
                            buttons: [
                                {
                                    text: '+Add',
                                    onClick: '',
                                },
                            ],
                        },
                        placeholder: '',
                        infoText: '',
                        validations: {},
                        k: 'Locations',
                        type: 'table',
                        onChange: '',
                    },
                ],
                placeholder: '',
                infoText: '',
                validations: {},
                k: 'PracticeLocations',
                type: 'array',
                onChange: '',
            },
        ],
    },
];

export const FINANCIAL_STATEMENT_INPUTS = [
    {
        sectionHeader: 'Section 2',
        fields: [
            {
                label: '17. Valuation Date',
                value: null,
                options: [],
                k: 'LTMPeriodEnd',
                type: 'dropdownList',
                isDisabled: false,
                placeholder: 'Enter LTM period end date here',
                infoText:
                    'Select the month end date corresponding to<br/> the most recent calendar month for which<br/> you have complete accounting data.',
                validations: {
                    required: { value: true },
                    pattern: 'MM/DD/YYYY',
                },
                onChange: '',
            },
            {
                label: '18. Revenue',
                value: {
                    header: '',
                    columns: [
                        { label: '' },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '$ Values (Enter by Period):',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'Revenue-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'Revenue-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'Revenue-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'Revenue-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'Revenue',
                type: 'table',
                placeholder: '',
                infoText:
                    'All service and product revenue, net of<br/> discounts, other.  Enter calendar YTD period<br/> as of the month end specified in the LTM<br/> period, while the Prev. Year YTD period is the<br/> same period occurring one-year prior.<br/>  Example, if LTM date is 8/31/2019, YTD is the<br/> period from 1/1/2019 to 8/31/2019 and Prev.<br/> Year YTD is the period from 1/1/2018 to<br/> 8/31/2018',
                validations: {},
                onChange: '',
            },
            {
                label: '19. Direct Costs',
                value: {
                    header: 'Direct Costs',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'DirectCosts-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'DirectCosts-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'DirectCosts-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'DirectCosts-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'DirectCosts',
                type: 'table',
                placeholder: '',
                infoText:
                    'Total cost of goods sold for all services and<br/> products sold during the applicable period.<br/> This includes FDA medication, diet, laboratory<br/> fees, medical supply costs and other direct<br/> cost. <u>Exclude</u> any payments to 1099 DVMs<br/> (relief, specialty, etc)',
                validations: {},
                onChange: '',
            },
            {
                label: '20. Owner Gross Compensation',
                value: {
                    header: '',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'OwnerCompensation-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'OwnerCompensation-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'OwnerCompensation-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'OwnerCompensation-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'OwnerCompensation',
                type: 'table',
                placeholder: '',
                infoText:
                    'All Gross wages paid to the owner(s) for his /<br/> her / their medical production, or other<br/> services provided to the practice. <u>Include</u> only<br/> those amounts present in the Income<br/> Statement of your financial statements.  Note:<br/> sometimes this number will be $0 for owners<br/> who do not pay themselves for medical<br/> production or management activities, but pay<br/> themselves from profit left over after all other<br/> expenses are paid',
                validations: {},
                onChange: '',
            },
            {
                label: '21. W-2 Affiliated DVM Gross Compensation',
                value: {
                    header: 'Affiliated DVM Compensation',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'AssociateDVMCompensation-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'AssociateDVMCompensation-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'AssociateDVMCompensation-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'AssociateDVMCompensation-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'AssociateDVMCompensation',
                type: 'table',
                placeholder: '',
                infoText:
                    'Total salary / ProSal Gross wages paid to W-2<br/> affiliated members. <u>Include</u> any bonuses. <u>Exclude</u> any<br/> non-cash benefits (i.e. meals, health<br/> insurance, retirement benefits)',
                validations: {},
                onChange: '',
            },
            {
                label: '22. 1099 DVM Compensation',
                value: {
                    header: 'Relief DVM Compensation',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'ReliefDVMCompensation-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'ReliefDVMCompensation-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'ReliefDVMCompensation-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'ReliefDVMCompensation-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'ReliefDVMCompensation',
                type: 'table',
                placeholder: '',
                infoText:
                    ' Total pay to 1099 relief, or specialists DVMs<br/> used, if any were used in the applicable<br/> period',
                validations: {},
                onChange: '',
            },
            {
                label: '23. Support Staff Compensation',
                value: {
                    header: 'Support Staff Compensation',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'SupportStaffCompensation-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'SupportStaffCompensation-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'SupportStaffCompensation-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'SupportStaffCompensation-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'SupportStaffCompensation',
                type: 'table',
                placeholder: '',
                infoText:
                    'Total Gross wages / salary expense for your<br/> support staff, including technicians/assistants,<br/> CSRs  and your practice manager, <u>Include</u> any<br/> bonuses. <u>Exclude</u> any wages included in<br/> 3/4/5 above. <u>Exclude</u> non-cash benefits',
                validations: {},
                onChange: '',
            },
            {
                label: '24. Other Labor Cost',
                value: {
                    header: 'Other Labor Cost',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'OtherLaborCosts-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'OtherLaborCosts-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'OtherLaborCosts-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'OtherLaborCosts-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'OtherLaborCosts',
                type: 'table',
                placeholder: '',
                infoText:
                    'Other direct expense supporting staff, or<br/> DVMs. <u>Include</u> continuing education, study<br/> group fees, team events, travel, parties or<br/> meals. <u>Exclude</u> benefits and insurance',
                validations: {},
                onChange: '',
            },
            {
                label: '25. Employee Fringe Benefits',
                value: {
                    header: 'Employee Fringe Benefits',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'EmployeeFringeBenefits-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'EmployeeFringeBenefits-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'EmployeeFringeBenefits-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'EmployeeFringeBenefits-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'EmployeeFringeBenefits',
                type: 'table',
                placeholder: '',
                infoText:
                    'Expense for benefit programs provided to<br/> employees (i.e. Dental, Group Health, life<br/> insurance, Disability, 401K / HSA<br/> contributions). <u>Exclude</u> benefits provided to<br/> owners who do not practice',
                validations: {},
                onChange: '',
            },
            {
                label: '26. Payroll Taxes',
                value: {
                    header: 'Payroll Taxes',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'PayrollTaxes-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'PayrollTaxes-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'PayrollTaxes-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'PayrollTaxes-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'PayrollTaxes',
                type: 'table',
                placeholder: '',
                infoText: 'Employer Payroll tax expense for all wages paid.',
                validations: {},
                onChange: '',
            },
            {
                label: '27. Workers Compensation  & Medical Liability Insurance',
                value: {
                    header: 'Workers Compensation',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'WorkersCompensation-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'WorkersCompensation-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'WorkersCompensation-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'WorkersCompensation-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'WorkersCompensation',
                type: 'table',
                placeholder: '',
                infoText:
                    'Premiums paid by the employer to insure<br/> against employee injury and medical liability',
                validations: {},
                onChange: '',
            },
            {
                label: '28. Merchant / Fee Income Collection Costs',
                value: {
                    header: 'Merchant / Fee Income Collection Costs',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'MerchantFeeIncomeCollectionCosts-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'MerchantFeeIncomeCollectionCosts-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'MerchantFeeIncomeCollectionCosts-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'MerchantFeeIncomeCollectionCosts-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'MerchantFeeIncomeCollectionCosts',
                type: 'table',
                placeholder: '',
                infoText:
                    'Expenses for bank charges, credit card /<br/> CareCredit service fees, bad debt / collection<br/> expense and other merchant expenses.<br/> <u>Exclude</u> all interest paid',
                validations: {},
                onChange: '',
            },
            {
                label: '29. Rent Expense',
                value: {
                    header: 'Rent Expense',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'RentExpense-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'RentExpense-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'RentExpense-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'RentExpense-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'RentExpense',
                type: 'table',
                placeholder: '',
                infoText:
                    'Rent or lease payments for practice facilities.<br/> <u>Exclude</u> equipment rental expense',
                validations: {},
                onChange: '',
            },
            {
                label: '30. Facility and Equipment Expense',
                value: {
                    header: 'Facility and Equipment Expense',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'FacilityEquipmentExpense-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'FacilityEquipmentExpense-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'FacilityEquipmentExpense-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'FacilityEquipmentExpense-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'FacilityEquipmentExpense',
                type: 'table',
                placeholder: '',
                infoText:
                    'Expenses for equipment or technology repair<br/> and maintenance, business equipment rent,<br/> utilities (include cable + internet), property<br/> taxes, property insurance, laundry / cleaning<br/> services, and others associated with facility or<br/> equipment maintenance',
                validations: {},
                onChange: '',
            },
            {
                label: '31. Advertising / Marketing',
                value: {
                    header: 'Advertising / Marketing',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'AdvertisingMarketing-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'AdvertisingMarketing-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'AdvertisingMarketing-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'AdvertisingMarketing-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'AdvertisingMarketing',
                type: 'table',
                placeholder: '',
                infoText:
                    'Promotion and advertising expense. <u>Include</u><br/> all paper and digital advertising',
                validations: {},
                onChange: '',
            },
            {
                label: '32. Administrative Costs',
                value: {
                    header: 'Administrative Costs',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: '',
                                    type: 'label',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'AdministrativeCosts-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'AdministrativeCosts-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'AdministrativeCosts-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'AdministrativeCosts-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: true,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'AdministrativeCosts',
                type: 'table',
                placeholder: '',
                infoText:
                    "Other cash operating expense such as<br/> professional services (recurring acct'g / tax<br/> prep. / legal), sales tax paid, payroll / benefits<br/> admin., printing and postage, computer and<br/> office supplies, licenses and permits. <u>Exclude</u><br/> nonprofit contributions",
                validations: {},
                onChange: '',
            },
        ],
    },
];

export const PRACTICE_MANAGEMENT_AND_OTHER_INPUTS = [
    {
        sectionHeader: 'Section 3',
        fields: [
            {
                label: '33. Number of Invoices',
                value: [
                    {
                        label: '',
                        value: {
                            header: '',
                            columns: [
                                {
                                    label: '',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place ',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place ',
                                },
                                {
                                    label: '{{ltm2}}',
                                    isDynamicLtm2: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place ',
                                },
                                {
                                    label: '{{ltm3}}',
                                    isDynamicLtm3: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place ',
                                },
                            ],
                            rows: [
                                {
                                    value: [
                                        {
                                            value: 'a. Values (Enter by period):',
                                            type: 'label',
                                        },
                                        {
                                            label: '{{ltm}}',
                                            isDynamicLtm: true,
                                            value: 0,
                                            k: 'NoofInvoices-LTM',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: '{{ltm1}}',
                                            isDynamicLtm1: true,
                                            value: 0,
                                            k: 'NoofInvoices-LTM1',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: 'YTD',
                                            value: 0,
                                            k: 'NoofInvoices-YTD',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: 'Previous Year YTD',
                                            value: 0,
                                            k: 'NoofInvoices-PrevYTD',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                            },
                                            onChange: '',
                                            isDisabled: false,
                                        },
                                    ],
                                    type: 'array',
                                },
                            ],
                        },
                        k: 'NoofInvoices',
                        type: 'table',
                        placeholder: '',
                        infoText:
                            "Provide number of invoices processed for each period; indicate in 'commentary' whether a change in PM, or other system, or incomplete data impacts comparable periods; Leave blank if this information is not collected",
                        validations: {},
                        onChange: '',
                    },
                    {
                        value: 'b. Commentary',
                        type: 'label',
                    },
                    {
                        label: 'Commentary',
                        value: '',
                        k: 'NoofInvoices-Commentary',
                        type: 'text',
                        placeholder: '',
                        infoText: '',
                        validations: {
                            required: { value: false },
                            pattern: {
                                value: '/[a-zA-z0-9]{0,255}/g',
                                errorMessage: 'Max 255 characters',
                            },
                        },
                        onChange: '',
                    },
                ],
                k: 'NoofInvoices',
                type: 'array',
                placeholder: '',
                infoText:
                    'Provide number of invoices processed for<br/> each period; indicate in commentary whether<br/> a change in PM, or other system, or<br/> incomplete data impacts comparable periods;<br/> Leave blank if this information is not<br/> collected',
                validations: {},
                onChange: '',
            },
            {
                label: '34. Number of Patient Visits',
                value: [
                    {
                        label: '',
                        value: {
                            header: '',
                            columns: [
                                {
                                    label: '',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                },
                                {
                                    label: '{{ltm2}}',
                                    isDynamicLtm2: true,
                                },
                                {
                                    label: '{{ltm3}}',
                                    isDynamicLtm3: true,
                                },
                            ],
                            rows: [
                                {
                                    value: [
                                        {
                                            value: 'a. Values (Enter by period):',
                                            type: 'label',
                                        },
                                        {
                                            label: '{{ltm}}',
                                            isDynamicLtm: true,
                                            value: 0,
                                            k: 'NoofPatientVisists-LTM',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: '{{ltm1}}',
                                            isDynamicLtm1: true,
                                            value: 0,
                                            k: 'NoofPatientVisists-LTM1',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: 'YTD',
                                            value: 0,
                                            k: 'NoofPatientVisists-YTD',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: 'Previous Year YTD',
                                            value: 0,
                                            k: 'NoofPatientVisists-PrevYTD',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                            },
                                            onChange: '',
                                            isDisabled: false,
                                        },
                                    ],
                                    type: 'array',
                                },
                            ],
                        },
                        k: 'NoofPatientVisists',
                        type: 'table',
                        placeholder: '',
                        infoText:
                            "Provide total number of patients seen for<br/> each period; indicate in 'commentary' whether<br/> a change in PM, or other system, or<br/> incomplete data impacts comparable periods.<br/>  Leave blank if this information is not<br/> collected",
                        validations: {},
                        onChange: '',
                    },
                    {
                        value: 'b. Commentary',
                        type: 'label',
                    },
                    {
                        label: 'Commentary',
                        value: '',
                        k: 'NoofPatientVisists-Commentary',
                        type: 'text',
                        placeholder: '',
                        infoText: '',
                        validations: {
                            required: { value: false },
                            pattern: {
                                value: '/[a-zA-z0-9]{0,255}/g',
                                errorMessage: 'Max 255 characters',
                            },
                        },
                        onChange: '',
                    },
                ],
                k: 'NoofPatientVisists',
                type: 'array',
                placeholder: '',
                infoText:
                    'Provide total number of patients seen for<br/> each period; indicate in commentary whether<br/> a change in PM, or other system, or<br/> incomplete data impacts comparable periods.<br/>  Leave blank if this information is not<br/> collected',
                validations: {},
                onChange: '',
            },
            {
                label: '35. Number of Active Patients',
                value: 0,
                infoText:
                    "Provide total number of unique patients seen within<br/> the last two years; indicate in 'commentary' whether<br/> a change in PM, or other system, or incomplete data<br/> impacts comparable periods.  Leave blank if<br/> this information is not collected",
                type: 'number',
                k: 'ActivePatients-NoofActivePatients',
                placeholder: 'Enter number of current patients',
                validations: {
                    required: { value: true },
                    min: { value: 0, errorMessage: 'Minimum value is 0' },
                    max: {
                        value: 9999999999,
                        errorMessage: 'Max value is 9999999999',
                    },
                },
                onChange: '',
            },
            {
                label: '36. Enacted Fee Increases',
                value: {
                    header: 'Enacted Fee Increases',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isLtmOrYTD: true,
                            infoText:
                                "Enter in decimals representing %, (i.e. 10% entered as '.1')",
                        },
                        {
                            label: '{{ltm}}',
                            isLtm1OrLtm: true,
                            infoText:
                                "Enter in decimals representing %, (i.e. 10% entered as '.1')",
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: 'Values (Enter by period):',
                                    type: 'label',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'EnactedFeeIncrease-YTD',
                                    type: 'percentage',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: true },
                                        min: {
                                            value: 0,
                                            errorMessage: 'Minimum value is 0',
                                        },
                                        max: {
                                            value: 100,
                                            errorMessage: 'Max value is 100',
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'EnactedFeeIncrease-LTM',
                                    type: 'percentage',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: true },
                                        min: {
                                            value: 0,
                                            errorMessage: 'Minimum value is 0',
                                        },
                                        max: {
                                            value: 100,
                                            errorMessage: 'Max value is 100',
                                        },
                                    },
                                    onChange: '',
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'EnactedFeeIncrease',
                type: 'table',
                placeholder: '',
                infoText:
                    'Add annual estimated percent increase in the practice<br/> fee schedule.  These are price<br/> increases implemented by management.<br/>  Enter a % as a whole number (i.e. 5 for 5%)',
                validations: {},
                onChange: '',
            },
            {
                label: '37. Staff - Clinical Support Staff',
                value: [
                    {
                        label: '',
                        value: {
                            header: '',
                            columns: [
                                {
                                    label: '',
                                },
                                {
                                    label: 'Current FTE',
                                    infoText:
                                        'Enter whole numbers for number of staff employed',
                                },
                                // {
                                //     "label": "{{ltm}}",
                                //     "isDynamicLtm": true,
                                //     "infoText": "Enter whole numbers for number of staff employed"
                                // },
                                // {
                                //     "label": "{{ltm1}}",
                                //     "isDynamicLtm1": true,
                                //     "infoText": "Enter whole numbers for number of staff employed"
                                // }
                            ],
                            rows: [
                                {
                                    value: [
                                        {
                                            value: 'Number of Clinical Support Staff',
                                            type: 'label',
                                        },
                                        {
                                            label: 'LTM',
                                            value: 0,
                                            k: 'ClinicalSupportStaff-NoofClinicalSupportStaff-LTM',
                                            type: 'number',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        'Minimum value is 0',
                                                },
                                                max: {
                                                    value: 9999999999,
                                                    errorMessage:
                                                        'Max value is 9999999999',
                                                },
                                            },
                                            onChange: '',
                                        },
                                        // {
                                        //     "label": "{{ltm}}",
                                        //     "isDynamicLtm": true,
                                        //     "value": 0,
                                        //     "k": "ClinicalSupportStaff-NoofClinicalSupportStaff-LTM1",
                                        //     "type": "number",
                                        //     "placeholder": "",
                                        //     "infoText": "",
                                        //     "validations": {
                                        //         "required": { value: true },
                                        //         "min": { value: 0, errorMessage: "Minimum value is 0" },
                                        //         "max": { value: 9999999999, errorMessage: "Max value is 9999999999" }
                                        //     },
                                        //     "onChange": ""
                                        // },
                                        // {
                                        //     "label": "{{ltm1}}",
                                        //     "isDynamicLtm1": true,
                                        //     "value": 0,
                                        //     "k": "ClinicalSupportStaff-NoofClinicalSupportStaff-LTM2",
                                        //     "type": "number",
                                        //     "placeholder": "",
                                        //     "infoText": "",
                                        //     "validations": {
                                        //         "required": { value: true },
                                        //         "min": { value: 0, errorMessage: "Minimum value is 0" },
                                        //         "max": { value: 9999999999, errorMessage: "Max value is 9999999999" }
                                        //     },
                                        //     "onChange": ""
                                        // }
                                    ],
                                    type: 'array',
                                },
                                {
                                    value: [
                                        {
                                            value: 'Number of Boarding / Grooming Staff',
                                            type: 'label',
                                        },
                                        {
                                            label: 'LTM',
                                            value: 0,
                                            k: 'ClinicalSupportStaff-NoofBoardingGrooming-LTM',
                                            type: 'number',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        'Minimum value is 0',
                                                },
                                                max: {
                                                    value: 9999999999,
                                                    errorMessage:
                                                        'Max value is 9999999999',
                                                },
                                            },
                                            onChange: '',
                                        },
                                        // {
                                        //     "label": "{{ltm}}",
                                        //     "isDynamicLtm": true,
                                        //     "value": 0,
                                        //     "k": "ClinicalSupportStaff-NoofBoardingGrooming-LTM1",
                                        //     "type": "number",
                                        //     "placeholder": "",
                                        //     "infoText": "",
                                        //     "validations": {
                                        //         "required": { value: true },
                                        //         "min": { value: 0, errorMessage: "Minimum value is 0" },
                                        //         "max": { value: 9999999999, errorMessage: "Max value is 9999999999" }
                                        //     },
                                        //     "onChange": ""
                                        // },
                                        // {
                                        //     "label": "{{ltm1}}",
                                        //     "isDynamicLtm1": true,
                                        //     "value": 0,
                                        //     "k": "ClinicalSupportStaff-NoofBoardingGrooming-LTM2",
                                        //     "type": "number",
                                        //     "placeholder": "",
                                        //     "infoText": "",
                                        //     "validations": {
                                        //         "required": { value: true },
                                        //         "min": { value: 0, errorMessage: "Minimum value is 0" },
                                        //         "max": { value: 9999999999, errorMessage: "Max value is 9999999999" }
                                        //     },
                                        //     "onChange": ""
                                        // }
                                    ],
                                    type: 'array',
                                },
                            ],
                        },
                        k: 'ClinicalSupportStaff',
                        type: 'table',
                        placeholder: '',
                        infoText:
                            'Number of FT Clinical support staff employed for each period.  Any fractional year periods, can be described in the commentary',
                        validations: {},
                        onChange: '',
                    },
                    {
                        value: 'Commentary',
                        type: 'label',
                    },
                    {
                        label: 'Commentary',
                        value: '',
                        k: 'ClinicalSupportStaff-Commentary',
                        type: 'text',
                        placeholder: '',
                        infoText:
                            'Type commentary to provide any additional context',
                        validations: {
                            required: { value: false },
                            pattern: {
                                value: '/[a-zA-z0-9]{0,255}/g',
                                errorMessage: 'Max 255 characters',
                            },
                        },
                        onChange: '',
                    },
                ],
                k: 'ClinicalSupportStaff',
                type: 'array',
                placeholder: '',
                infoText:
                    '# of Full-time-equivalent (FTE) clinical<br/> support staff employed currently. Any<br/> additional details about clinical staff can be<br/> described in the commentary.',
                validations: {},
                onChange: '',
            },
            {
                label: '38. Staff - Non-clinical Support Staff',
                value: [
                    {
                        label: '',
                        value: {
                            header: '',
                            columns: [
                                {
                                    label: '',
                                },
                                {
                                    label: 'Current FTE',
                                    infoText:
                                        'Enter whole numbers for number of staff employed',
                                },
                                // {
                                //     "label": "{{ltm}}",
                                //     "isDynamicLtm": true,
                                //     "infoText": "Enter whole numbers for number of staff employed"
                                // },
                                // {
                                //     "label": "{{ltm1}}",
                                //     "isDynamicLtm1": true,
                                //     "infoText": "Enter whole numbers for number of staff employed"
                                // }
                            ],
                            rows: [
                                {
                                    value: [
                                        {
                                            value: 'Number of Customer Support Staff',
                                            type: 'label',
                                        },
                                        {
                                            label: 'LTM',
                                            value: 0,
                                            k: 'NonClinicalSupportStaff-NoofCustomerSupportStaff-LTM',
                                            type: 'number',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        'Minimum value is 0',
                                                },
                                                max: {
                                                    value: 9999999999,
                                                    errorMessage:
                                                        'Max value is 9999999999',
                                                },
                                            },
                                            onChange: '',
                                        },
                                        // {
                                        //     "label": "{{ltm}}",
                                        //     "isDynamicLtm": true,
                                        //     "value": 0,
                                        //     "k": "NonClinicalSupportStaff-NoofCustomerSupportStaff-LTM1",
                                        //     "type": "number",
                                        //     "placeholder": "",
                                        //     "infoText": "",
                                        //     "validations": {
                                        //         "required": { value: true },
                                        //         "min": { value: 0, errorMessage: "Minimum value is 0" },
                                        //         "max": { value: 9999999999, errorMessage: "Max value is 9999999999" }
                                        //     },
                                        //     "onChange": ""
                                        // },
                                        // {
                                        //     "label": "{{ltm1}}",
                                        //     "isDynamicLtm1": true,
                                        //     "value": 0,
                                        //     "k": "NonClinicalSupportStaff-NoofCustomerSupportStaff-LTM2",
                                        //     "type": "number",
                                        //     "placeholder": "",
                                        //     "infoText": "",
                                        //     "validations": {
                                        //         "required": { value: true },
                                        //         "min": { value: 0, errorMessage: "Minimum value is 0" },
                                        //         "max": { value: 9999999999, errorMessage: "Max value is 9999999999" }
                                        //     },
                                        //     "onChange": ""
                                        // }
                                    ],
                                    type: 'array',
                                },
                                {
                                    value: [
                                        {
                                            value: 'Number of Other non-clinical Support Staff (include Practice Managers)',
                                            type: 'label',
                                        },
                                        {
                                            label: 'LTM',
                                            value: 0,
                                            k: 'NonClinicalSupportStaff-NoofOtherNonClinicalSupportStaff-LTM',
                                            type: 'number',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: { value: true },
                                                min: {
                                                    value: 0,
                                                    errorMessage:
                                                        'Minimum value is 0',
                                                },
                                                max: {
                                                    value: 9999999999,
                                                    errorMessage:
                                                        'Max value is 9999999999',
                                                },
                                            },
                                            onChange: '',
                                        },
                                        // {
                                        //     "label": "{{ltm}}",
                                        //     "isDynamicLtm": true,
                                        //     "value": 0,
                                        //     "k": "NonClinicalSupportStaff-NoofOtherNonClinicalSupportStaff-LTM1",
                                        //     "type": "number",
                                        //     "placeholder": "",
                                        //     "infoText": "",
                                        //     "validations": {
                                        //         "required": { value: true },
                                        //         "min": { value: 0, errorMessage: "Minimum value is 0" },
                                        //         "max": { value: 9999999999, errorMessage: "Max value is 9999999999" }
                                        //     },
                                        //     "onChange": ""
                                        // },
                                        // {
                                        //     "label": "{{ltm1}}",
                                        //     "isDynamicLtm1": true,
                                        //     "value": 0,
                                        //     "k": "NonClinicalSupportStaff-NoofOtherNonClinicalSupportStaff-LTM2",
                                        //     "type": "number",
                                        //     "placeholder": "",
                                        //     "infoText": "",
                                        //     "validations": {
                                        //         "required": { value: true },
                                        //         "min": { value: 0, errorMessage: "Minimum value is 0" },
                                        //         "max": { value: 9999999999, errorMessage: "Max value is 9999999999" }
                                        //     },
                                        //     "onChange": ""
                                        // }
                                    ],
                                    type: 'array',
                                },
                            ],
                        },
                        k: 'NonClinicalSupportStaff',
                        type: 'table',
                        placeholder: '',
                        infoText:
                            'Number of FT non-clinical support staff employed for each period.  Any fractional year periods, can be described in the commentary',
                        validations: {},
                        onChange: '',
                    },
                    {
                        value: 'Commentary',
                        type: 'label',
                    },
                    {
                        label: 'Commentary',
                        value: '',
                        k: 'NonClinicalSupportStaff-Commentary',
                        type: 'text',
                        placeholder: '',
                        infoText:
                            'Type commentary to provide any additional context',
                        validations: {
                            required: { value: false },
                            pattern: {
                                value: '/[a-zA-z0-9]{0,255}/g',
                                errorMessage: 'Max 255 characters',
                            },
                        },
                        onChange: '',
                    },
                ],
                k: 'NonClinicalSupportStaff',
                type: 'array',
                placeholder: '',
                infoText:
                    '# of Full-time-equivalent (FTE) non-clinical<br/> support staff employed currently. Any<br/> additional details about non-clinical staff can<br/> be described in the commentary.',
                validations: {},
                onChange: '',
            },
        ],
    },
];

export const NORMALIZING_ADJUSTMENTS = [
    {
        sectionHeader: 'Section 4',
        fields: [
            {
                label: '39. Corporate Adjustments – Owner’s Personal Expenses',
                value: {
                    header: 'Corporate Adjustments – Owner’s Personal Expenses',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: 'Insurance',
                                    type: 'label',
                                    infoText:
                                        'Supplemental Insurance expense related to<br/> owners (life insurance and disability',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-Insurance-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-Insurance-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-Insurance-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-Insurance-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                        {
                            value: [
                                {
                                    value: 'Automobile',
                                    type: 'label',
                                    infoText:
                                        "Costs related to owners' personal vehicle use<br/> (leasing, insurance, maintenance etc.)",
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-Automobile-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-Automobile-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-Automobile-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-Automobile-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                        {
                            value: [
                                {
                                    value: 'Owner Travel and Entertainment',
                                    type: 'label',
                                    infoText:
                                        "Expense related to owner's personal travel<br/> and entertainment",
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-TE-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-TE-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-TE-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-TE-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                        {
                            value: [
                                {
                                    value: 'All Other Owner Specific Expenses',
                                    type: 'label',
                                    infoText:
                                        "All Other Expenses related to owner's<br/> personal use",
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-OtherOwnerExpenses-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-OtherOwnerExpenses-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-OtherOwnerExpenses-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'CorpoateAdjustmentsPersonalExpenses-OtherOwnerExpenses-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'CorpoateAdjustmentsPersonalExpenses',
                type: 'table',
                placeholder: '',
                infoText:
                    'Enter all expenses that apply in $s.  Do not<br/> include any expenses that appear on your<br/> income statement below operating profit, or<br/> that are NOT included in the expense items in<br/> section C.  Our goal is to Normalize these<br/> items out of your PNL.',
                validations: {},
                onChange: '',
            },
            {
                label: "40. Excess / (Shortfall) in Owner's Clinical Compensation",
                value: {
                    header: "40. Excess / (Shortfall) in Owner's Clinical Compensation",
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: "a. Clinician Owner's Production",
                                    type: 'label',
                                    // "infoText": "Supplemental Insurance expense related to owners (life insurance and disability"
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-ClinicianOwnersProduction-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-ClinicianOwnersProduction-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-ClinicianOwnersProduction-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-ClinicianOwnersProduction-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                            ],
                            k: 'OwnersClinicalCompensation-ClinicianOwnersProduction',
                            type: 'array',
                        },
                        {
                            value: [
                                {
                                    value: "b. Does the owner compensation entered in #20 amount to the owners' full compensation included in Salary and Wages for clinical activities performed in this practice?  ",
                                    type: 'label',
                                    infoText:
                                        'If you answer ‘No’, please enter the portion<br/> of your salary in #20 that compensates for<br/> “Clinical” (in c), or “Management” (in d)<br/> activities. The amounts in C + D should sum<br/> to your salary in question #20.',
                                },
                            ],
                            type: 'array',
                        },
                        {
                            value: [
                                {
                                    label: "b. Does the owner compensation entered in #20 amount to the owners' full compensation included in Salary and Wages for clinical activities performed in this practice?  ",
                                    value: 1,
                                    k: 'OwnersClinicalCompensation-IsOwnerFullCompensation',
                                    type: 'dropdownList',
                                    options: [
                                        { text: 'Yes', value: 1 },
                                        {
                                            text: 'No, owner’s compensation in #20 contains clinical and managerial compensation',
                                            value: 2,
                                        },
                                    ],
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                },
                            ],
                            type: 'array',
                        },
                        {
                            value: [
                                {
                                    value: "c. Owner's Clinical Salary",
                                    type: 'label',
                                    // "infoText": "Supplemental Insurance expense related to owners (life insurance and disability"
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-OwnersClinicalSalary-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                    isDisabled: true,
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-OwnersClinicalSalary-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                    isDisabled: true,
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-OwnersClinicalSalary-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                    isDisabled: true,
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-OwnersClinicalSalary-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                    isDisabled: true,
                                    shouldDisable: true,
                                },
                            ],
                            k: 'OwnersClinicalCompensation-OwnersClinicalSalary',
                            type: 'array',
                        },
                        {
                            value: [
                                {
                                    value: "d. Owner's Salary for Mgmt Other",
                                    type: 'label',
                                    // "infoText": "Supplemental Insurance expense related to owners (life insurance and disability"
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-OwnersSalaryToMgmt-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                    isDisabled: true,
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-OwnersSalaryToMgmt-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                    isDisabled: true,
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-OwnersSalaryToMgmt-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                    isDisabled: true,
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'OwnersClinicalCompensation-OwnersSalaryToMgmt-PrevYTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                    },
                                    onChange: '',
                                    isDisabled: true,
                                    shouldDisable: true,
                                },
                            ],
                            k: 'OwnersClinicalCompensation-OwnersSalaryToMgmt',
                            type: 'array',
                        },
                    ],
                },
                k: 'OwnersClinicalCompensation',
                type: 'table',
                placeholder: '',
                // "infoText": "his is info text for excess in owner's clinical compensation",
                validations: {},
                onChange: '',
            },
            {
                label: "41. Excess / (Shortfall) in Owner's Management Compensation",
                value: [
                    {
                        label: '',
                        value: {
                            header: '',
                            columns: [
                                {
                                    label: '',
                                },
                                {
                                    label: '',
                                },
                            ],
                            rows: [
                                {
                                    value: [
                                        {
                                            value: 'a. I have a practice manager',
                                            type: 'label',
                                        },
                                        {
                                            label: 'a. I have a practice manager',
                                            options: [
                                                { text: 'Yes', value: 1 },
                                                { text: 'No', value: 2 },
                                            ],
                                            value: null,
                                            k: 'OwnersManagementCompensation-HasPracticeManager',
                                            // "infoText": "If yes, please enter the quantity of such purchases made in the applicable period",
                                            placeholder: '',
                                            validations: {},
                                            type: 'dropdownList',
                                        },
                                    ],
                                    type: 'array',
                                },
                            ],
                        },
                        k: 'OwnersManagementCompensation',
                        type: 'table',
                        placeholder: '',
                        infoText: '',
                        validations: {},
                        onChange: '',
                    },
                    {
                        label: '',
                        value: {
                            header: '',
                            columns: [
                                {
                                    label: '',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place',
                                },
                                {
                                    label: '{{ltm2}}',
                                    isDynamicLtm2: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place',
                                },
                                {
                                    label: '{{ltm3}}',
                                    isDynamicLtm3: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place',
                                },
                            ],
                            rows: [
                                {
                                    value: [
                                        {
                                            value: 'b. Practice Manager Gross Salary',
                                            type: 'label',
                                        },
                                        {
                                            label: '{{ltm}}',
                                            isDynamicLtm: true,
                                            value: 0,
                                            k: 'OwnersManagementCompensation-PracticeManagerSalary-LTM',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: {
                                                    value: false,
                                                },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: '{{ltm1}}',
                                            isDynamicLtm1: true,
                                            value: 0,
                                            k: 'OwnersManagementCompensation-PracticeManagerSalary-LTM1',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: {
                                                    value: false,
                                                },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: 'YTD',
                                            value: 0,
                                            k: 'OwnersManagementCompensation-PracticeManagerSalary-YTD',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: {
                                                    value: false,
                                                },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: 'Previous Year YTD',
                                            value: 0,
                                            k: 'OwnersManagementCompensation-PracticeManagerSalary-PrevYTD',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: {
                                                    value: false,
                                                },
                                            },
                                            onChange: '',
                                            isDisabled: false,
                                        },
                                    ],
                                    type: 'array',
                                },
                                {
                                    value: [
                                        {
                                            value: 'c. Of 100% of total management activities by you and your practice manager, what % do you perform? (In percents.“100” = 100%)',
                                            type: 'label',
                                        },
                                        {
                                            label: 'c. Of 100% of total management activities by you and your practice manager, what % do you perform?',
                                            value: 0,
                                            k: 'OwnersManagementCompensation-YourActivityPercentage',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: {
                                                    value: false,
                                                },
                                            },
                                            onChange: '',
                                        },
                                    ],
                                    type: 'array',
                                },
                            ],
                        },
                        k: 'OwnersManagementCompensation',
                        type: 'table',
                        placeholder: '',
                        infoText: '',
                        validations: {},
                        onChange: '',
                    },
                ],
                k: 'OwnersManagementCompensation',
                // "infoText": "Do your expenses reflect Medicine / Drug Purchase that cover more than one annual period?",
                placeholder: '',
                validations: {},
                type: 'array',
            },
            {
                label: '42. Corporate Adjustments - Rent Expense',
                value: {
                    header: 'Corporate Adjustments - Rent Expense',
                    columns: [{ label: '' }, { label: '' }],
                    rows: [
                        {
                            value: [
                                {
                                    value: 'a. Do you own your real-estate?',
                                    type: 'label',
                                },
                                {
                                    label: 'a. Do you own your real-estate?',
                                    value: 1,
                                    k: 'CorporateAdjustmentsRentExpenses-OwnRealEstate',
                                    type: 'dropdownList',
                                    options: [
                                        { text: 'Yes', value: 1 },
                                        { text: 'No', value: 2 },
                                    ],
                                    placeholder: '',
                                    infoText:
                                        'Yes I own it and lease it to myself OR No,<br/> I lease from a 3rd party',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                            ],
                            type: 'array',
                        },
                        {
                            value: [
                                {
                                    value: `b. Please estimate an annual "market" rent for your facility. `,
                                    type: 'label',
                                },
                                {
                                    label: `b. Please estimate an annual "market" rent for your facility. `,
                                    value: '',
                                    k: 'CorporateAdjustmentsRentExpenses-EstimateMarketRent',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: ``,
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                            ],
                            type: 'array',
                        },
                        {
                            value: [
                                {
                                    value: `c. Do you believe your current rent is consistent with your local "market"?`,
                                    type: 'label',
                                },
                                {
                                    label: `c. Do you believe your current rent is consistent with your local "market"?`,
                                    value: 2,
                                    k: 'CorporateAdjustmentsRentExpenses-IsConsistentWithMarket',
                                    options: [
                                        { text: 'Yes', value: 1 },
                                        { text: 'No', value: 2 },
                                    ],
                                    type: 'dropdownList',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                            ],
                            type: 'array',
                        },
                        {
                            value: [
                                {
                                    value: `If No, Please estimate an annual "market" rent for your facility. `,
                                    type: 'label',
                                },
                                {
                                    label: `If No, Please estimate an annual "market" rent for your facility. `,
                                    value: '',
                                    k: 'CorporateAdjustmentsRentExpenses-NoEstimateRent',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                k: 'CorporateAdjustmentsRentExpenses',
                type: 'table',
                placeholder: '',
                // }],
                // "k": "CorporateAdjustmentsRentExpenses",
                // "type": "array",
                // "placeholder": "",
                infoText:
                    'How does your rent differ from a market<br/> rent?  Estimate the market rent for a<br/> comparable facility in your area.  If your rent is<br/> market, leave blank',
                validations: { required: { value: false } },
                onChange: '',
            },
            {
                label: '43. Cash to Accrual Adjustments - Drug Purchases',
                value: [
                    {
                        label: 'Do your expenses reflect Medicine / Drug<br/> Purchase that cover more than one annual<br/> period?',
                        options: [
                            { text: 'Yes', value: 1 },
                            { text: 'No', value: 2 },
                        ],
                        value: null,
                        k: 'CashAccrualAdjustmentsDrugVaccine-IsExpenseReflectMedicine',
                        infoText:
                            'If yes, please enter the $ value of such<br/> purchases made in the applicable period',
                        placeholder: '',
                        validations: {},
                        type: 'dropdownList',
                    },
                    {
                        label: '',
                        value: {
                            header: '',
                            columns: [
                                {
                                    label: '',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place ',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place ',
                                },
                                {
                                    label: '{{ltm2}}',
                                    isDynamicLtm2: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place ',
                                },
                                {
                                    label: '{{ltm3}}',
                                    isDynamicLtm3: true,
                                    infoText:
                                        'Enter in $s with no commas, 1 decimal place ',
                                },
                            ],
                            rows: [
                                {
                                    value: [
                                        {
                                            value: 'Drug / Vaccine Purchases',
                                            type: 'label',
                                        },
                                        {
                                            label: '{{ltm}}',
                                            isDynamicLtm: true,
                                            value: 0,
                                            k: 'CashAccrualAdjustmentsDrugVaccine-DrugVaccinePurchase-LTM',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: {
                                                    value: false,
                                                },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: '{{ltm1}}',
                                            isDynamicLtm1: true,
                                            value: 0,
                                            k: 'CashAccrualAdjustmentsDrugVaccine-DrugVaccinePurchase-LTM1',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: {
                                                    value: false,
                                                },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: 'YTD',
                                            value: 0,
                                            k: 'CashAccrualAdjustmentsDrugVaccine-DrugVaccinePurchase-YTD',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: {
                                                    value: false,
                                                },
                                            },
                                            onChange: '',
                                        },
                                        {
                                            label: 'Previous Year YTD',
                                            value: 0,
                                            k: 'CashAccrualAdjustmentsDrugVaccine-DrugVaccinePurchase-PrevYTD',
                                            type: 'currency',
                                            placeholder: '',
                                            infoText: '',
                                            validations: {
                                                required: {
                                                    value: false,
                                                },
                                            },
                                            onChange: '',
                                            isDisabled: false,
                                        },
                                    ],
                                    type: 'array',
                                },
                            ],
                        },
                        k: 'DrugVaccinePurchase',
                        type: 'table',
                        placeholder: '',
                        infoText: '',
                        validations: {},
                        onChange: '',
                    },
                ],
                k: 'CashAccrualAdjustmentsDrugVaccine',
                infoText:
                    'Do your expenses reflect Medicine / Drug<br/> Purchase that cover more than one annual<br/> period?',
                placeholder: '',
                validations: {},
                type: 'array',
            },
            {
                label: '44. Cash to Accrual Adjustments - Other',
                value: {
                    header: 'Cash to Accrual Adjustments - Other',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: 'Item descrip.',
                            infoText: 'short description',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: 'Expected Useful Life',
                            infoText:
                                'Enter number of years over which the expense should be amortized',
                        },
                        {
                            label: '',
                            grow: 0.2,
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: 'Item 1',
                                    type: 'label',
                                },
                                {
                                    label: 'Expense Item',
                                    value: '',
                                    k: 'CashAccrualAdjustmentsOther-LstExpenseItem-0-ExpenseItem',
                                    type: 'text',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                        pattern: {
                                            value: '/[a-zA-z0-9]{0,100}/g',
                                            errorMessage: 'Max 100 characters',
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'CashAccrualAdjustmentsOther-LstExpenseItem-0-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'CashAccrualAdjustmentsOther-LstExpenseItem-0-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'CashAccrualAdjustmentsOther-LstExpenseItem-0-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'CashAccrualAdjustmentsOther-LstExpenseItem-0-Prev_YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                                {
                                    label: 'Expected Useful Life',
                                    value: 0,
                                    k: 'CashAccrualAdjustmentsOther-LstExpenseItem-0-ExpectedUsefulLife',
                                    type: 'number',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                        min: {
                                            value: 0,
                                            errorMessage: 'Minimum value is 0',
                                        },
                                        max: {
                                            value: 999,
                                            errorMessage: 'Max value is 999',
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '',
                                    value: null,
                                    k: `CashAccrualAdjustmentsOther-LstExpenseItem-0-Remove`,
                                    type: '',
                                    grow: 0.2,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                toolbar: {
                    buttons: [
                        {
                            text: '+Add',
                            onClick: '',
                        },
                    ],
                },
                k: 'CashAccrualAdjustmentsOther',
                type: 'table',
                placeholder: '',
                infoText:
                    'Any other expenses that appear in your<br/> income statement that do not reflect the<br/> annual benefit of the expense? Example -<br/> upfront licensing fees on a software.<br/> Expected useful Life is the period (in years)<br/> over which the expense will be amortized for<br/> the purposes of adjustment. Enter $ Values<br/> by period',
                validations: {},
                onChange: '',
            },
            {
                label: '45. One-Off Items - Capital Investments',
                value: {
                    header: 'One-Off Items - Capital Investments',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: 'Item descrip.',
                            infoText: 'short description',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '',
                            grow: 0.2,
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: 'Item 1',
                                    type: 'label',
                                },
                                {
                                    label: 'Expense Item',
                                    value: '',
                                    k: 'OneOffItemsCapitalInvestments-LstExpenseItem-0-ExpenseItem',
                                    type: 'text',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                        pattern: {
                                            value: '/[a-zA-z0-9]{0,100}/g',
                                            errorMessage: 'Max 100 characters',
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'OneOffItemsCapitalInvestments-LstExpenseItem-0-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'OneOffItemsCapitalInvestments-LstExpenseItem-0-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'OneOffItemsCapitalInvestments-LstExpenseItem-0-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'OneOffItemsCapitalInvestments-LstExpenseItem-0-Prev_YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                                {
                                    label: '',
                                    value: null,
                                    k: `OneOffItemsCapitalInvestments-LstExpenseItem-0-Remove`,
                                    type: '',
                                    grow: 0.2,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                toolbar: {
                    buttons: [
                        {
                            text: '+Add',
                            onClick: '',
                        },
                    ],
                },
                k: 'OneOffItemsCapitalInvestments',
                type: 'table',
                placeholder: '',
                infoText:
                    'Quantify any major capital investments<br/> completed in the applicable period that were<br/> expensed (appear in your income statement<br/> expenses). Expected Useful Life is the period<br/> (in years) over which the expense will be<br/> amortized for the purposes of adjustment.<br/> Enter $ Values by period',
                validations: {},
                onChange: '',
            },
            {
                label: '46. One-Off Items - Other',
                value: {
                    header: 'One-Off Items - Other',
                    columns: [
                        {
                            label: '',
                        },
                        {
                            label: 'Item descrip.',
                            infoText: 'short description',
                        },
                        {
                            label: '{{ltm}}',
                            isDynamicLtm: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm1}}',
                            isDynamicLtm1: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm2}}',
                            isDynamicLtm2: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '{{ltm3}}',
                            isDynamicLtm3: true,
                            infoText:
                                'Enter in $s with no commas, 1 decimal place ',
                        },
                        {
                            label: '',
                            grow: 0.2,
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: 'Item 1',
                                    type: 'label',
                                },
                                {
                                    label: 'Expense Item',
                                    value: '',
                                    k: 'OneOffItemsOther-LstExpenseItem-0-ExpenseItem',
                                    type: 'text',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: false },
                                        pattern: {
                                            value: '/[a-zA-z0-9]{0,100}/g',
                                            errorMessage: 'Max 100 characters',
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm}}',
                                    isDynamicLtm: true,
                                    value: 0,
                                    k: 'OneOffItemsOther-LstExpenseItem-0-LTM',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '{{ltm1}}',
                                    isDynamicLtm1: true,
                                    value: 0,
                                    k: 'OneOffItemsOther-LstExpenseItem-0-LTM1',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'YTD',
                                    value: 0,
                                    k: 'OneOffItemsOther-LstExpenseItem-0-YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: 'Previous Year YTD',
                                    value: 0,
                                    k: 'OneOffItemsOther-LstExpenseItem-0-Prev_YTD',
                                    type: 'currency',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: {
                                            value: false,
                                        },
                                    },
                                    onChange: '',
                                    isDisabled: false,
                                },
                                {
                                    label: '',
                                    value: null,
                                    k: `OneOffItemsOther-LstExpenseItem-0-Remove`,
                                    type: '',
                                    grow: 0.2,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                toolbar: {
                    buttons: [
                        {
                            text: '+Add',
                            onClick: '',
                        },
                    ],
                },
                k: 'OneOffItemsOther',
                type: 'table',
                placeholder: '',
                infoText:
                    'Quantify one-time expenses impacting your<br/> income statement. Example - signing bonus<br/> paid to a doctor, or clean-up / impact from a<br/> natural disaster. Enter $ Values by period',
                validations: {},
                onChange: '',
            },
            {
                label: '47. Doctor Grid',
                value: {
                    header: 'Doctor Grid',
                    columns: [
                        {
                            label: 'Clinician Code',
                        },
                        {
                            label: 'Full or Part-Time',
                        },
                        {
                            label: 'Type',
                            grow: 2,
                        },
                        {
                            label: 'Currently Employed by You',
                        },
                        {
                            label: 'Date Joined',
                            infoText:
                                'If doctor was Hired, or Departed the practice within the period since the beginning of calendar year 2017, add the applicable date, otherwise leave the fields blank',
                        },
                        {
                            label: 'Date Departed, if applicable',
                            infoText:
                                'If doctor was Hired, or Departed the practice within the period since the beginning of calendar year 2017, add the applicable date, otherwise leave the fields blank',
                        },
                        {
                            label: 'Hours per Week',
                            infoText:
                                'If the doctor is a part-time doctor, enter the number of hours worked per week',
                            grow: 0.5,
                        },
                        {
                            label: '',
                            grow: 0.2,
                        },
                    ],
                    rows: [
                        {
                            value: [
                                {
                                    value: 'Doctor #1',
                                    type: 'label',
                                },
                                {
                                    label: 'Full or Part-Time',
                                    value: null,
                                    options: [
                                        { text: 'Full Time', value: 1 },
                                        { text: 'Part Time', value: 2 },
                                    ],
                                    k: 'Doctor-0-FT_PT',
                                    type: 'dropdownList',
                                    placeholder: '',
                                    infoText: '',
                                    validations: { required: { value: true } },
                                    onChange: '',
                                },
                                {
                                    label: 'Type',
                                    value: null,
                                    options: [
                                        {
                                            text: 'Companion Animal - GP',
                                            value: 1,
                                        },
                                        {
                                            text: 'Companion Animal - Specialist',
                                            value: 2,
                                        },
                                        {
                                            text: 'Companion Animal - Emergency',
                                            value: 3,
                                        },
                                        { text: 'Equine', value: 4 },
                                        { text: 'Large Animal', value: 5 },
                                        {
                                            text: 'Boarding / Grooming',
                                            value: 6,
                                        },
                                        { text: 'Other', value: 7 },
                                    ],
                                    k: 'Doctor-0-ServiceMixType',
                                    type: 'dropdownList',
                                    placeholder: '',
                                    infoText: '',
                                    validations: { required: { value: true } },
                                    onChange: '',
                                    grow: 2,
                                },
                                {
                                    label: 'Currently Employed by You',
                                    value: null,
                                    options: [
                                        { text: 'Yes', value: 1 },
                                        { text: 'No', value: 2 },
                                    ],
                                    k: 'Doctor-0-IsEmployedByYou',
                                    type: 'dropdownList',
                                    placeholder: '',
                                    infoText: '',
                                    validations: { required: { value: true } },
                                    onChange: '',
                                },
                                {
                                    label: 'Date Joined',
                                    value: null,
                                    k: 'Doctor-0-DateOfJoining',
                                    type: 'date',
                                    placeholder: '',
                                    infoText: '',
                                    validations: { required: { value: false } },
                                    onChange: '',
                                },
                                {
                                    label: 'Date Departed',
                                    value: null,
                                    k: 'Doctor-0-DateOfDeparture',
                                    type: 'date',
                                    placeholder: '',
                                    infoText: '',
                                    validations: { required: { value: false } },
                                    onChange: '',
                                },
                                {
                                    label: 'Hours per Week',
                                    value: 0,
                                    grow: 0.5,
                                    k: 'Doctor-0-HoursPerWeeks',
                                    type: 'number',
                                    placeholder: '',
                                    infoText: '',
                                    validations: {
                                        required: { value: true },
                                        min: {
                                            value: 0,
                                            errorMessage: 'Minimum value is 0',
                                        },
                                        max: {
                                            value: 999,
                                            errorMessage: 'Max value is 999',
                                        },
                                    },
                                    onChange: '',
                                },
                                {
                                    label: '',
                                    value: null,
                                    k: `Doctor-0-Remove`,
                                    type: '',
                                    grow: 0.2,
                                },
                            ],
                            type: 'array',
                        },
                    ],
                },
                toolbar: {
                    buttons: [
                        {
                            text: '+Add',
                            onClick: '',
                        },
                    ],
                },
                k: 'Doctor',
                type: 'table',
                placeholder: '',
                infoText:
                    'Doctor Grid - Enter the following details for<br/> each W-2 DVM (include DVM Owners who<br/> practice) who has seen patients at your<br/> practice since the beginning of the most<br/> recently completed calendar year. <u>Exclude</u><br/> any 1099 DVMs. <u>Include</u> any DVMs who left<br/> the practice after the start of the most<br/> recently completed calendar year.  You may<br/> leave Hours per Week blank for any Full-time<br/> (FT) DVMs.',
                validations: {},
                onChange: '',
            },
        ],
    },
];

export const HUMAN_READABLE_NUMBERS = [
    { from: 4, to: 6, divisor: 1000, symbol: 'K', name: 'thousands' },
    { from: 7, to: 9, divisor: 1000000, symbol: 'M', name: 'millions' },
    { from: 10, to: 12, divisor: 1000000000, symbol: 'B', name: 'billions' },
    {
        from: 13,
        to: 15,
        divisor: 1000000000000,
        symbol: 'T',
        name: 'trillions',
    },
];

export const CAPVAR_DYNAMIC_LABELS = [
    { key: 'isLtmOrYTD', values: ['ltm', 'ltm2'] },
    { key: 'isLtm1OrLtm', values: ['ltm1', 'ltm'] },
    { key: 'isDynamicLtm', values: ['ltm'] },
    { key: 'isDynamicLtm1', values: ['ltm1'] },
    { key: 'isDynamicLtm2', values: ['ltm2'] },
    { key: 'isDynamicLtm3', values: ['ltm3'] },
];

export const PMG_STATUSES_ENUM = {
    OPEN_ACTIVE: 1,
    CLOSED_ACTIVE: 2,
    OPEN_INACTIVE: 3,
    CLOSED_INACTIVE: 4,
};

export const MEMBER_ACTIVITY_PERIOD = 90;

export const NOT_EMPTY_OR_WHITESPACE_REGEX = /(?!^$)([^\s])/;

export const RELOAD_COUNT = 5;

export const ReportStatus = {
    INREVIEW: 0,
    ACCEPTED: 1,
    REJECTED: 2,
    INPROCESS: 3,
};

import React, { useEffect, useState } from 'react';
import './ExpandedText.scss';

export interface ExpandedTextProps {
    content: string;
    uniqueId: string;
    heightToShowLinksPx: number;
    className?: string;
    clampLines?: number;
}

export const ExpandedText = (props: ExpandedTextProps) => {
    const [clampLines] = useState(props.clampLines ?? 3);

    useEffect(() => {
        checkEllipsis();
    }, []);

    function toggleLines() {
        const elementId = props.uniqueId;
        var label = document.getElementById(`feedbackLabel${elementId}`);
        var showMoreLink = document.getElementById(`showMoreLink${elementId}`);
        var showLessLink = document.getElementById(`showLessLink${elementId}`);

        if (label.classList.contains('expanded')) {
            label.classList.remove('expanded');
            showMoreLink.style.display = 'inline';
            showLessLink.style.display = 'none';
        } else {
            label.classList.add('expanded');
            showMoreLink.style.display = 'none';
            showLessLink.style.display = 'inline';
        }
    }

    const checkEllipsis = () => {
        const label = document.getElementById(`feedbackLabel${props.uniqueId}`);
        const showMoreLink = document.getElementById(
            `showMoreLink${props.uniqueId}`
        );
        const showLessLink = document.getElementById(
            `showLessLink${props.uniqueId}`
        );
        if (label && showMoreLink && showLessLink) {
            if (label.scrollHeight > props.heightToShowLinksPx) {
                showMoreLink.style.display = 'inline';
            } else {
                showMoreLink.style.display = 'none';
            }
        }
    };

    return (
        <div className={`expanded-text ${props.className ?? ''}`}>
            <div
                className="text-label"
                id={`feedbackLabel${props.uniqueId}`}
                style={{ WebkitLineClamp: clampLines }}>
                {props.content}
            </div>
            <div>
                <a
                    id={`showMoreLink${props.uniqueId}`}
                    style={{ display: 'none' }}
                    onClick={toggleLines}>
                    Show More
                </a>
                <a
                    id={`showLessLink${props.uniqueId}`}
                    style={{ display: 'none' }}
                    onClick={toggleLines}>
                    Show Less
                </a>
            </div>
        </div>
    );
};

import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { PaymentDialog, PriceItem } from '../PaymentDialog';
import createNotification from '../../../utils/createNotification';
import { useStateSelector } from '../../../store/selectors';

interface GiftCardPaymentComponent {
    isVisible: boolean;
    setIsVisible: (isVisible: React.SetStateAction<boolean>) => void;
    paymentSuccess: () => void;
    recipientWalletId: number,
    giftCardAmount: number,
    vvServiceType: number,
    directMessageNote: string;
    senderAffiliatedMemberWalletId?: number;
}

export const GiftCardPaymentComponent = (props: GiftCardPaymentComponent) => {
    const axios = useStateSelector((state) => state.core.axios);
    const stripe = useStripe();
    const elements = useElements();
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [groupRebatePercent, setGroupRebatePercent] = useState(0);
    const [originalServicePrice, setOriginalServicePrice] = useState(
        props.giftCardAmount
    );
    const [totalPrice, setTotalPrice] = useState(props.giftCardAmount);
    const [appliedCredits, setAppliedCredits] = useState(0);
    const [isPaymentInProcess, setIsPaymentInProcess] = useState(false);
    const [serviceFinalPayment, setServiceFinalPayment] = useState(
        props.giftCardAmount
    );

    const directPayment = () => chargeDeposit(false, '');

    const chargeDeposit = (
        shouldUseSavedPaymentMethod: boolean,
        paymentTokenId: string = ''
    ) => {
        setIsPaymentInProcess(true);
        const postObject = {
            recipientWalletId: props.recipientWalletId === 0 ? null : props.recipientWalletId,
            senderAffiliatedMemberWalletId: props.senderAffiliatedMemberWalletId,
            giftCardAmount: props.giftCardAmount,
            price: props.giftCardAmount, // TODO: Why do we have this duplicated?
            walletAmount: appliedCredits,
            useSavedPaymentMethod: shouldUseSavedPaymentMethod,
            paymentSystemMethodId: paymentTokenId,
            directMessageNote: `<p>${props.directMessageNote}</p>`,
            vvServiceType: props.vvServiceType
        };
        axios
            .post('/api/giftCards', postObject)
            .then((response: any) => {
                if (response?.request?.status === 200) {
                    setTimeout(() => {
                        props.paymentSuccess();
                        props.setIsVisible(false);
                    }, 2000);
                } else {
                    createNotification(
                        response?.request.message ?? 'Payment failed',
                        'error'
                    );
                    setIsPaymentInProcess(false);
                    props.setIsVisible(false);
                }
            })
            .catch(() => {
                createNotification('Unexpected error occurred.', 'error');
                props.setIsVisible(false);
            });
    };

    const mapPriceList = (): PriceItem[] => {
        const priceList: PriceItem[] = [
            {
                priceType: 'summary',
                label: 'Gift Card Value:',
                value: props.giftCardAmount,
                valueType: 'currency',
            },
        ];

        return priceList;
    };

    const calculateTotalPrice = () => serviceFinalPayment - appliedCredits;

    useEffect(() => {
        setTotalPrice(calculateTotalPrice());
    }, [appliedCredits, originalServicePrice]);

    return (
        <div className="gift-card-payment-component">
            <PaymentDialog
                isVisible={props.isVisible}
                setIsVisible={props.setIsVisible}
                directPayment={directPayment}
                stripe={stripe}
                elements={elements}
                priceList={mapPriceList()}
                originalServicePrice={originalServicePrice}
                servicePrice={serviceFinalPayment}
                totalProductPrice={totalPrice}
                apliedCredits={appliedCredits}
                setAppliedCredits={(value) => setAppliedCredits(value)}
                groupOrderRebatePercent={groupRebatePercent}
                isParrentLoading={isDataLoading}
                isPaymentInProcess={isPaymentInProcess}
                chargeRequest={chargeDeposit}
                isAuthorizationRequired={false}
                disableStoreCredits={true}
                netPaymentLable="Net Payment Due:"></PaymentDialog>
        </div>
    );
};

export default GiftCardPaymentComponent;

import React, { useEffect, useState } from 'react';
import './DataTemplateTable.scss';
import { Button } from 'reactstrap';
import BootstrapTable, {
    TableChangeState,
    TableChangeType,
} from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import { useNavigate } from 'react-router-dom';
import { useStateSelector } from '../../../../../store/selectors';
import createNotification from '../../../../../utils/createNotification';
import moment from 'moment';
import ConfirmationModal from '../../../../../components/Modal/ConfirmationModal';
import { DataForm } from '../../../../../models/DataRequestHub/DataForm';
import { TableFilter } from '../../../../../components/Table/models/table-filter';
import { objectToQueryString } from '../../../../../utils/queryHelper';
import { EditorModeEnum } from '../../ProjectEditorHost/ProjectEditorHost';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { DataFormType } from '../../../../../models/DataRequestHub/DataFormTypeEnum';
import DataLoader from '../../../../../components/DataLoader/DataLoader';
import DataFormTemplate from '../../../../../models/DataRequestHub/DataFormTemplate';
import { ExpandedText } from '../../../../DataRequest/ExpandedText/ExpandedText';

export interface DataTemplateTableProps {
    templateType: DataFormType;
    tabNumber: number;
}

export const DataTemplateTable = (props: DataTemplateTableProps) => {
    const axios = useStateSelector((s) => s.core.axios);
    const navigate = useNavigate();
    const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState(0);
    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [tableData, setTableData] = useState<DataForm[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const defaultSortDirection: { dataField: string; order: 'asc' | 'desc' } = {
        dataField: 'dateCreated',
        order: 'desc',
    };
    const tableTooltipTargetId = 'data-and-document-requests-dashboard';
    const [sortingState, setSortingState] = useState({
        data: [],
        page: page,
        sizePerPage: sizePerPage,
        sortField: defaultSortDirection.dataField,
        sortOrder: defaultSortDirection.order,
    });

    useEffect(() => {
        if (sortingState) {
            fetchTemplateProject(sortingState);
        }
    }, [sortingState]);

    const createDataFormCopy = (dataForm: DataForm) => {
        const templateQuestions = dataForm.questions;
        const templateName = dataForm.originalTemplateName + ' - Copy';
        axios
            .post('/api/dataRequestTemplateProject/dataForms', {
                originalTemplateName: templateName,
                formType: dataForm.formType,
                questions: templateQuestions,
            } as DataFormTemplate)
            .then((response) => {
                if (response.status === 200) {
                    fetchTemplateProject(sortingState);
                } else {
                    createNotification(
                        'An error occured while creating template',
                        'error'
                    );
                }
            });
    };

    const getColumns = () => [
        { dataField: 'id', text: '', hidden: true },
        {
            dataField: 'originalTemplateName',
            text: 'Template Name',
            sort: true,
            formatter: (cellContent: string, row: DataForm) => (
                <ExpandedText
                    content={cellContent}
                    uniqueId={`original-template-name-${row.id}`}
                    heightToShowLinksPx={32}
                    clampLines={2}></ExpandedText>
            ),
        },
        {
            dataField: 'dateCreated',
            text: 'Added On',
            sort: true,
            formatter: (cellContent: Date) =>
                moment(cellContent).format('MM/DD/YYYY'),
        },

        {
            dataField: 'id',
            text: 'Action',
            formatter: (cellContent: number, row: DataForm) => (
                <div className="action-icons">
                    <FontAwesomeIcon
                        onClick={() => {
                            createDataFormCopy(row);
                        }}
                        icon={faCopy}
                    />
                    <FontAwesomeIcon
                        onClick={() => {
                            navigate('/project-editor', {
                                state: {
                                    dataForms: [row],
                                    mode: EditorModeEnum.EditTempalte,
                                    type: props.templateType,
                                    additionalUrlToReturn: `?tab=${props.tabNumber}`,
                                },
                            });
                        }}
                        icon={faEdit}
                    />
                    <FontAwesomeIcon
                        className="delete-button"
                        onClick={() => {
                            setSelectedTemplateId(cellContent);
                            setIsConfirmationVisible(true);
                        }}
                        icon={faTrashCan}
                    />
                </div>
            ),
        },
    ];

    useEffect(() => {
        const newSortState = {
            data: [] as DataForm[],
            page: 1,
            sizePerPage: 5,
            sortField: defaultSortDirection.dataField,
            sortOrder: defaultSortDirection.order,
        };
        setSortingState(newSortState);
        fetchTemplateProject(newSortState);
    }, [props]);

    const fetchTemplateProject = (sortingState: any) => {
        let tableFilter = new TableFilter(
            {
                filters: [
                    {
                        field: 'FormType',
                        value: props.templateType.toString(),
                        operator: 'eq',
                    },
                ],
                logic: '',
            },
            sortingState.page,
            sortingState.sizePerPage,
            {
                dir: sortingState.sortOrder ?? defaultSortDirection.order,
                field: sortingState.sortField ?? defaultSortDirection.dataField,
            }
        );
        axios
            .get(
                `/api/dataRequestTemplateProject/dataForms/paginated?${objectToQueryString(
                    tableFilter
                )}`
            )
            .then((response) => {
                if (response.status === 200) {
                    const pagination = response.data;
                    setTotalSize(pagination.totalItems);
                    setPage(pagination.pageNumber);
                    setSizePerPage(pagination.pageSize);
                    setTableData(pagination.list);
                } else {
                    createNotification(
                        'An error occured while fetching templates',
                        'error'
                    );
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleTableChange = (
        _type: TableChangeType,
        newState: TableChangeState<any>
    ) => {
        setSortingState(newState);
    };

    const removeDataTemplate = (documentTemplateId: number) => {
        axios
            .delete(
                `/api/dataRequestTemplateProject/dataForms/${documentTemplateId}`
            )
            .then((response) => {
                if (response.status === 200) {
                    fetchTemplateProject(sortingState);
                } else {
                    createNotification(
                        'An error occured while fetching templates',
                        'error'
                    );
                }
            })
            .catch(() => {
                createNotification(
                    'An error occured while fetching templates',
                    'error'
                );
            });
    };

    return (
        <div className="documents-templates">
            {isLoading ? (
                <DataLoader />
            ) : (
                <>
                    <div className="mb-3">
                        <strong>Current Templates</strong>
                        <Button
                            type="button"
                            className="btn btn-primary ml-3"
                            onClick={() => {
                                navigate('/project-editor', {
                                    state: {
                                        type: props.templateType,
                                        mode: EditorModeEnum.CreateTemplate,
                                        additionalUrlToReturn: `?tab=${props.tabNumber}`,
                                    },
                                });
                            }}>
                            Add New Template
                        </Button>
                    </div>
                    <BootstrapTable
                        striped
                        bordered
                        bootstrap4
                        remote
                        keyField="id"
                        data={tableData}
                        columns={getColumns()}
                        filter={filterFactory()}
                        pagination={paginationFactory({
                            page,
                            sizePerPage,
                            totalSize,
                            tooltipTargetId: tableTooltipTargetId,
                        })}
                        defaultSorted={[defaultSortDirection]}
                        onTableChange={handleTableChange}
                        noDataIndication="No Data available"
                    />
                </>
            )}

            {isConfirmationVisible ? (
                <ConfirmationModal
                    isVisible={isConfirmationVisible}
                    setIsVisible={setIsConfirmationVisible}
                    header="Confirmation"
                    confirmationText={`Are you sure you want to remove this ${
                        props.templateType == DataFormType.Documents
                            ? 'document'
                            : 'financial requests'
                    } template?`}
                    onConfirm={removeDataTemplate}
                    params={selectedTemplateId}
                    nextButtonText="Yes, I'm sure"
                    cancelButtonText="No, take me back"></ConfirmationModal>
            ) : (
                []
            )}
        </div>
    );
};

import React, { useEffect, useRef, useState } from 'react';
import './DataTableForm.scss';
import {
    DataTable,
    DataTableColumn,
    DataTableRow,
    DataTableProgressStatusList,
    DataTableViewProgressStatus,
    TableViewProgressStatusList,
    DataTableProgressStatus,
} from '../../../models/DataRequestHub/DataTable';
import { GridColDef, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid';
import EditableTable from '../../../components/EditableTable/EditableTable';
import { EditorTypeEnum } from '../../Admin/DataRequest/ProjectEditorHost/ProjectEditorHost';
import { useStateSelector } from '../../../store/selectors';
import createNotification from '../../../utils/createNotification';
import { CustomIdConstants } from '../../../models/DataRequestHub/CustomIdConstants';
import DataTableFormActionColumn from './DataTableFormActionColumn';
import ButtonLoader from '../../../components/Layout/Buttons/ButtonLoader';
import {
    AnswerTypeVariable,
    AnswerTypeVariableNamesList,
    FormTypeVariable,
    FormTypeVariableNamesList,
    ProjectVariable,
} from '../../../models/DataRequestHub/ProjectVariable';
import { objectToQueryString } from '../../../utils/queryHelper';
import { NumericColumn } from './NumericColumn/NumericColumn';

interface DataTableFormProps {
    projectId: number;
    variables: ProjectVariable[];
    dataTable: DataTable;
    selectedTableViewId: number;
    isAdminView: boolean;
    updateProjectsData(isInitialFetch?: boolean): void;
}

export const DataTableForm = (props: DataTableFormProps) => {
    const apiRef = useGridApiRef();
    const [tableData, setTableData] = useState<DataTableRow[]>([]);
    const gridTableRef = useRef(null);
    const axios = useStateSelector((s) => s.core.axios);
    const [dataTable, setDataTable] = useState<DataTable>(null);
    const [isRequestPending, setIsRequestPending] = useState(false);
    const [isStatusUpdateInProcess, setIsStatusUpdateInProcess] =
        useState(false);
    const [markAsCompleteState, setMarkAsCompleteState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    const getCurrentTableView = () =>
        props.dataTable.views.find((x) => x.id === props.selectedTableViewId);

    const getCurrentTableViewColumns = () => {
        const selectedView = getCurrentTableView();

        const columnIds =
            selectedView?.viewColumns
                .sort(
                    (a, b) => a.overridenDisplayOrder - b.overridenDisplayOrder
                )
                .map((x) => x.customColumnId) ??
            props.dataTable.columns
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map((x) => x.customColumnId);

        return columnIds
            .map((id) =>
                props.dataTable.columns.find(
                    (x) => x.customColumnId === id && x.isVisible
                )
            )
            .filter((x) => x != null);
    };

    const getVariableOptionsByName = (name: string) => {
        const variable = props.variables.find((x) => x.name === name);
        const result = variable.options.map((x) => x.option);
        return result;
    };

    const isReadonlyAccess = () => {
        const isTableComplete =
            props.dataTable.progressStatus === DataTableProgressStatus.Complete;
        const isCurrentViewConfirmed =
            getCurrentTableView()?.progressStatus ===
            DataTableViewProgressStatus.Confirmed;

        return isTableComplete || isCurrentViewConfirmed;
    };

    const columns = (): GridColDef[] => {
        const isFormEditable = !isReadonlyAccess();
        return getCurrentTableViewColumns().map((x) => {
            switch (x.answerType) {
                case AnswerTypeVariableNamesList.find(
                    (u) => u.id === AnswerTypeVariable.Form
                ).name:
                    return getDropDownColumn(x);
                case AnswerTypeVariableNamesList.find(
                    (u) => u.id === AnswerTypeVariable.Number
                ).name:
                    return getNumericDolumn(x);
                case AnswerTypeVariableNamesList.find(
                    (u) => u.id === AnswerTypeVariable.Text
                ).name:
                    break;
                case 'Date':
                    break;
                default:
                    break;
            }

            let result = {
                field: x.customColumnId,
                headerName: x.name,
                minWidth: 150,
                flex: 0.5,
                type: 'string',
                cellClassName: 'cell-text-input',
                editable: isFormEditable,
            } as GridColDef;

            return result;
        });
    };

    const getNumericDolumn = (
        columnDefinition: DataTableColumn
    ): GridColDef => {
        const cellClassName = 'cell-text-input numeric-cell';
        const renderEditCell = (params: any) => (
            <NumericColumn
                isReadonly={false}
                formType={columnDefinition.formType}
                {...params}
            />
        );

        const renderCell = (params: any) => (
            <NumericColumn
                isReadonly={true}
                formType={columnDefinition.formType}
                {...params}
            />
        );
        let result = {
            field: columnDefinition.customColumnId,
            headerName: columnDefinition.name,
            minWidth: 150,
            flex: 0.5,
            cellClassName: cellClassName,
            editable: true,
            renderEditCell: renderEditCell,
            renderCell: renderCell,
        } as GridColDef;

        return result;
    };

    const getDropDownColumn = (
        columnDefinition: DataTableColumn
    ): GridColDef => {
        let type = 'singleSelect';
        let valueOptions = [''];
        let cellClassName = 'cell-text-input';

        if (
            columnDefinition.formType ===
            FormTypeVariableNamesList.find(
                (u) => u.id === FormTypeVariable.DropDown
            ).name
        ) {
            valueOptions = getVariableOptionsByName(columnDefinition.formList);
        } else if (
            columnDefinition.formType ===
            FormTypeVariableNamesList.find(
                (u) => u.id === FormTypeVariable.YesNo
            ).name
        ) {
            valueOptions = ['Yes', 'No'];
        }
        let result = {
            field: columnDefinition.customColumnId,
            headerName: columnDefinition.name,
            minWidth: 150,
            flex: 0.5,
            type: type,
            valueOptions: valueOptions,
            cellClassName: cellClassName,
            editable: true,
        } as GridColDef;

        return result;
    };

    const updateTableData = () => {
        let rowList = props.dataTable.rows.filter(
            (x) => (x.isVisible && !x.isRemovalRequested) || props.isAdminView
        );
        const columns = getCurrentTableViewColumns();

        const result = rowList.map((row) => {
            const newRow = { ...row } as any;

            row.cells.forEach((y) => {
                const column = columns.find(
                    (x) => x.customColumnId === y.customColumnId
                );
                if (column) {
                    newRow[column.customColumnId] = y.answerText;
                }
            });
            return newRow;
        });

        setTableData(result);
    };

    const clearRowsFromCellProperties = (
        updatedRows: DataTableRow[]
    ): DataTableRow[] => {
        return updatedRows.map((updatedRow) => {
            const newCells = updatedRow.cells.map((cell) => {
                const newAnswerText = updatedRow[
                    cell.customColumnId as keyof typeof updatedRow
                ] as string;
                const isNullOrUndefined =
                    newAnswerText === undefined || newAnswerText === null;
                return {
                    ...cell,
                    answerText: isNullOrUndefined
                        ? cell.answerText
                        : newAnswerText,
                };
            });

            return {
                id: updatedRow.id,
                customRowId: updatedRow.customRowId,
                isRemovalRequested: updatedRow.isRemovalRequested,
                isVisible: updatedRow.isVisible,
                cells: newCells,
            };
        });
    };

    const validateAllRows = () => {
        let isValid = true;

        return isValid;
    };

    const updateRows = (
        newRows: (oldRows: DataTableRow[]) => DataTableRow[]
    ): void => {
        const result = newRows(tableData);
        setTableData(result);
        debounceUpdateDataTableRows(result);
    };

    const filterUpdatedRows = (tableRows: DataTableRow[]) => {
        const updatedRows = tableRows.filter((tableRow) => {
            const originRow = dataTable.rows.find((f) => f.id === tableRow.id);

            if (originRow) {
                const hasAnyCellDifference = originRow.cells.some(
                    (originCell) => {
                        const tableCell = tableRow.cells.find(
                            (cell) => cell.id === originCell.id
                        );

                        if (tableCell) {
                            const tableCellAnswer = tableCell.answerText ?? '';
                            const originalTableCellAnswer =
                                originCell.answerText ?? '';
                            return (
                                tableCellAnswer !== originalTableCellAnswer ||
                                originRow.isVisible !== tableRow.isVisible
                            );
                        } else {
                            return false;
                        }
                    }
                );

                return hasAnyCellDifference;
            }

            return false;
        });

        return updatedRows;
    };

    const debounceUpdateDataTableRows = (tableRows: DataTableRow[]) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            const celanRows = clearRowsFromCellProperties(tableRows);
            const updatedRows = filterUpdatedRows(celanRows);

            if (!updatedRows.length) {
                return;
            }

            axios
                .put(
                    `/api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableRows`,
                    updatedRows
                )
                .then((response) => {
                    if (response.status !== 200) {
                        const message = (response as any)?.response?.data
                            ?.detail;
                        createNotification(
                            message ?? 'An error occurred while updating row',
                            'error'
                        );
                    }

                    props.updateProjectsData();
                });
        }, 2000);
    };

    useEffect(() => {
        validateAllRows();
    }, [tableData]);

    useEffect(() => {
        if (
            JSON.stringify(dataTable?.rows) !==
                JSON.stringify(props.dataTable.rows) ||
            JSON.stringify(dataTable?.columns) !==
                JSON.stringify(props.dataTable.columns)
        ) {
            updateTableData();
            setDataTable(props.dataTable);
        }
    }, [props.dataTable]);

    useEffect(() => {
        if (props.selectedTableViewId) {
            const selectedTableView = getCurrentTableView();
            const markAsComplete = props.isAdminView
                ? selectedTableView.isComplete
                : selectedTableView.isReadyForReview;

            setMarkAsCompleteState(markAsComplete);
        }
    }, [props]);

    const handleAddNewRow = () => {
        if (isRequestPending) return;

        setIsRequestPending(true);
        const ids = props.dataTable.rows.map((x) => x.customRowId);
        const newTableRowId =
            CustomIdConstants.getNextDataTableRowCustomId(ids);
        axios
            .post(
                `/api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableRows`,
                { customDataTableRowId: newTableRowId }
            )
            .then((response) => {
                if (response.status === 200) {
                    props.updateProjectsData();
                } else {
                    const message = (response as any)?.response?.data?.detail;
                    createNotification(
                        message ?? 'An error occurred while adding row',
                        'error'
                    );
                }
            })
            .finally(() => {
                setIsRequestPending(false);
            });
    };

    const renderAddNewRow = () => (
        <ButtonLoader
            onClick={handleAddNewRow}
            buttonText={'+ Add New Row'}
            className={'btn btn-primary'}
            loaderButtonText={''}
            isLoading={isRequestPending}
            disabled={isRequestPending || isReadonlyAccess()}></ButtonLoader>
    );

    const getRowId = (row: GridValidRowModel) => row.customRowId;

    const customActionColumn = DataTableFormActionColumn({
        setRows: updateRows,
        gridApiRef: apiRef,
        getRowId: getRowId,
        projectId: props.projectId,
        dataTableId: props.dataTable.id,
        updateProjectsData: props.updateProjectsData,
        isAdmin: props.isAdminView,
    });

    const handleTableViewStatusChange = (
        newStatus: DataTableViewProgressStatus
    ) => {
        const currentTableView = getCurrentTableView();
        setIsStatusUpdateInProcess(true);
        axios
            .put(
                `api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableViews/${currentTableView.id}/progressStatus`,
                { progressStatus: newStatus }
            )
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while updating view status. Please, try again'
                    );
                } else {
                    props.updateProjectsData();
                }
            })
            .finally(() => {
                setIsStatusUpdateInProcess(false);
            });
    };

    const updateMarkAsCompleteState = () => {
        setIsLoading(true);

        const updateUrl = props.isAdminView
            ? `/isComplete?${objectToQueryString({
                  isComplete: markAsCompleteState,
              })}`
            : `/isReadyForApproval?${objectToQueryString({
                  isReadyForApproval: markAsCompleteState,
              })}`;

        axios
            .put(
                `api/dataRequestProjects/${props.projectId}/dataTables/${props.dataTable.id}/dataTableViews/${props.selectedTableViewId}${updateUrl}`
            )
            .then((response) => {
                if (response.status !== 200) {
                    createNotification(
                        'An error occured while updating complete status',
                        'error'
                    );
                    setMarkAsCompleteState(!markAsCompleteState);
                } else {
                    props.updateProjectsData();
                }
            })
            .finally(() => setIsLoading(false));
    };

    const getInitialMarkAsCompleteState = () => {
        const selectedTableView = getCurrentTableView();
        const initialMarkAsCompleteState = props.isAdminView
            ? selectedTableView.isComplete
            : selectedTableView.isReadyForReview;

        return initialMarkAsCompleteState;
    };

    const isViewAnswersComplete = () => {
        const currentView = getCurrentTableView();
        const includedColumnCustomIds = currentView.viewColumns.flatMap(
            (viewColumn) => viewColumn.customColumnId
        );
        const visibleCells = props.dataTable.rows.flatMap((row) => {
            const cells = row.cells.filter((cell) =>
                includedColumnCustomIds.some(
                    (includedColumnCustomId) =>
                        includedColumnCustomId === cell.customColumnId
                )
            );

            return cells;
        });

        const isVisibleCellsAnswerComplete =
            visibleCells.every(
                (cell) =>
                    cell.answerText !== undefined &&
                    cell.answerText !== null &&
                    cell.answerText !== ''
            ) && visibleCells.length;

        return isVisibleCellsAnswerComplete;
    };

    const renderViewStautsBar = () => {
        const selectedTableView = getCurrentTableView();

        const statusLabel = props.isAdminView ? (
            <>
                <div className="table-view-progress-status-dropdown-wrapper">
                    <div className="status">{'Status: '}</div>
                    <select
                        disabled={isStatusUpdateInProcess}
                        value={selectedTableView.progressStatus}
                        style={{
                            color: TableViewProgressStatusList.find(
                                (x) =>
                                    x.id ==
                                    (selectedTableView.progressStatus ?? 0)
                            ).color,
                        }}
                        onChange={(event) => {
                            const value = Number(event.target.value);
                            handleTableViewStatusChange(value);
                        }}
                        name="table-view-progress-status"
                        className="table-view-progress-status-dropdown form-control">
                        {TableViewProgressStatusList.map((item, index) => (
                            <option
                                style={{ color: item.color }}
                                key={index + item.id + item.name}
                                value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
            </>
        ) : (
            <div className="table-view-progress-status-dropdown-wrapper">
                <div className="status">{'Status: '}</div>
                <div
                    style={{
                        color: TableViewProgressStatusList.find(
                            (x) => x.id == selectedTableView.progressStatus
                        ).color,
                        fontFamily: 'SegoeUI-Bold',
                    }}>
                    {
                        TableViewProgressStatusList.find(
                            (x) => x.id == selectedTableView.progressStatus
                        ).name
                    }
                </div>
            </div>
        );

        return (
            <>
                {statusLabel}
                <div className="mark-as-complete-section">
                    <input
                        disabled={!isViewAnswersComplete()}
                        checked={markAsCompleteState}
                        type="checkbox"
                        onChange={(event) => {
                            setMarkAsCompleteState(event.target.checked);
                        }}></input>
                    <label>{'Mark as Complete'}</label>
                    <ButtonLoader
                        buttonText={'Save'}
                        loaderButtonText={''}
                        disabled={
                            getInitialMarkAsCompleteState() ===
                            markAsCompleteState
                        }
                        isLoading={isLoading}
                        onClick={updateMarkAsCompleteState}
                        className="btn btn-primary"
                    />
                </div>
            </>
        );
    };

    const renderTableStatus = () => (
        <div className="table-view-progress-status-dropdown-wrapper">
            <div className="status">{'Status: '}</div>
            <div
                style={{
                    color: DataTableProgressStatusList.find(
                        (x) => x.id == (props.dataTable.progressStatus ?? 0)
                    ).color,
                    fontFamily: 'SegoeUI-Bold',
                }}>
                {
                    DataTableProgressStatusList.find(
                        (x) => x.id == (props.dataTable.progressStatus ?? 0)
                    ).name
                }
            </div>
        </div>
    );

    return (
        <div className="data-table-view-host">
            <div className="status-tool-bar">
                {props.selectedTableViewId
                    ? renderViewStautsBar()
                    : renderTableStatus()}
            </div>
            <EditableTable
                editorType={EditorTypeEnum.DataTableData}
                columns={columns()}
                rows={tableData}
                setRows={updateRows}
                fieldToFocus="displayOrder"
                ref={gridTableRef}
                gridApiRef={apiRef}
                validateAllRows={validateAllRows}
                customActionColumn={customActionColumn}
                disableVisibilityColumn={
                    !props.isAdminView || isReadonlyAccess()
                }
                disableActionColumn={isReadonlyAccess()}
            />
            {renderAddNewRow()}
        </div>
    );
};

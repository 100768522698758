import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
} from 'react';
import EditableTable from '../../../../../components/EditableTable/EditableTable';
import {
    GridColDef,
    GridPreProcessEditCellProps,
    useGridApiRef,
} from '@mui/x-data-grid';
import {
    ProjectVariable,
    ProjectVariableOption,
} from '../../../../../models/DataRequestHub/ProjectVariable';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextInputColumn from '../../../../../components/EditableTable/CustomColumns/TextInputColumn/TextInputColumn';
import { ExpandedText } from '../../../../DataRequest/ExpandedText/ExpandedText';
import Helpers from '../../../../../utils/helper';
import {
    validateGridEditorRowDisplayOrder,
    validateRequiredCell,
    validateUniqueItemName,
} from '../../../../../components/EditableTable/EditableTableValidationHelper';
import DataFormQuestion from '../../../../../models/DataRequestHub/DataFormQuestion';
import { EditorTypeEnum } from '../ProjectEditorHost';

interface DocumentEditorInterface {
    setIsProjectVariableEditPopupVisible(value: boolean): void;
    setVariableNameToEdit(value: string): void;
    setVarialbeIdToEdit(value: number): void;
    templateRows: DataFormQuestion[];
    setTemplateRows(rows: DataFormQuestion[]): void;
    setProjectVariableOptionsToEdit(options: ProjectVariableOption[]): void;
    isLoading: boolean;
    likelySourceVariable: ProjectVariable;
    displayFormatVariable: ProjectVariable;
}

const DocumentEditor = forwardRef((props: DocumentEditorInterface, ref) => {
    const apiRef = useGridApiRef();
    const {
        setVariableNameToEdit,
        setIsProjectVariableEditPopupVisible,
        templateRows,
        setTemplateRows,
        setVarialbeIdToEdit,
        setProjectVariableOptionsToEdit,
        isLoading,
        likelySourceVariable,
        displayFormatVariable,
    } = props;

    const gridTableRef = useRef(null);

    useImperativeHandle(ref, () => ({
        addRow() {
            gridTableRef.current.addRow();
        },
        validateAllRows() {
            return validateAllRows();
        },
    }));

    const columns = (): GridColDef[] => [
        {
            field: 'displayOrder',
            headerName: 'Display Order',
            minWidth: 140,
            flex: 0.25,
            type: 'number',
            cellClassName: 'cell-input display-order',
            editable: true,
            align: 'left',
            headerAlign: 'left',
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const value = params.props.value;
                const hasError = value <= 0;
                return { ...params.props, error: hasError };
            },
        },
        {
            field: 'displayName',
            headerName: 'Item Name',
            type: 'string',
            cellClassName: 'cell-text-input display-name',
            minWidth: 150,
            flex: 1,
            editable: true,
            renderCell: (params) => (
                <ExpandedText
                    content={params.row.displayName}
                    uniqueId={`displayName-input-${params?.id ?? 0}`}
                    heightToShowLinksPx={95}
                    className="MuiDataGrid-cellContent"
                />
            ),
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const value = params.props.value;
                const hasError = value?.trim().length <= 0;
                return { ...params.props, error: hasError };
            },
            renderEditCell: (params) => <TextInputColumn {...params} />,
        },
        {
            field: 'description',
            headerName: 'Description',
            minWidth: 150,
            flex: 1,
            type: 'string',
            cellClassName: 'cell-text-input description',
            editable: true,
            renderCell: (params) => (
                <ExpandedText
                    content={params.row.description}
                    uniqueId={`description-input-${params?.id ?? 0}`}
                    heightToShowLinksPx={95}
                />
            ),
            renderEditCell: (params) => <TextInputColumn {...params} />,
        },
        {
            field: 'likelySource',
            headerName: 'Likely Source',
            minWidth: 170,
            cellClassName: 'likely-source',
            flex: 0.5,
            type: 'singleSelect',
            renderHeader: () => {
                return (
                    <>
                        Likely Source
                        <FontAwesomeIcon
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsProjectVariableEditPopupVisible(true);
                                setVarialbeIdToEdit(likelySourceVariable.id);
                                setVariableNameToEdit(
                                    likelySourceVariable.name
                                );
                                setProjectVariableOptionsToEdit(
                                    likelySourceVariable.options
                                );
                            }}
                            style={{ marginLeft: '10px' }}
                            size="1x"
                            icon={faEdit as any}
                            title="Save"
                        />
                    </>
                );
            },
            valueOptions: likelySourceVariable?.options.map((x) => x.option),
            editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = params.props.value?.trim().length <= 0;
                return { ...params.props, error: hasError };
            },
        },
        {
            field: 'displayFileFormat',
            headerName: 'Display Format',
            cellClassName: 'display-file-format',
            minWidth: 170,
            flex: 0.5,
            type: 'singleSelect',
            renderHeader: () => {
                return (
                    <>
                        Display Format
                        <FontAwesomeIcon
                            onClick={(event) => {
                                event.stopPropagation();
                                setIsProjectVariableEditPopupVisible(true);
                                setVarialbeIdToEdit(displayFormatVariable.id);
                                setVariableNameToEdit(
                                    displayFormatVariable.name
                                );
                                setProjectVariableOptionsToEdit(
                                    displayFormatVariable.options
                                );
                            }}
                            style={{ marginLeft: '10px' }}
                            size="1x"
                            icon={faEdit as any}
                            title="Save"
                        />
                    </>
                );
            },
            valueOptions: displayFormatVariable?.options.map((x) => x.name),
            editable: true,
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                const hasError = params.props.value?.length <= 0;

                return { ...params.props, error: hasError };
            },
        },
        {
            field: 'allowedFileFormat',
            editable: true,
        },
        {
            field: 'storageFolder',
            headerName: 'Storage Folder',
            minWidth: 150,
            flex: 0.5,
            cellClassName: 'cell-text-input storage-folder',
            editable: true,
            renderCell: (params) => (
                <ExpandedText
                    content={params.row.storageFolder}
                    uniqueId={`storageFolder-input-${params?.id ?? 0}`}
                    heightToShowLinksPx={95}
                />
            ),
            preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                if (params.hasChanged) {
                    const value = params.props.value as string;
                    const hasError = Helpers.hasFoldersPathError(value, '/');

                    return { ...params.props, error: hasError };
                }

                return { ...params.props };
            },
            renderEditCell: (params) => <TextInputColumn {...params} />,
        },
    ];

    const validateAllRows = () => {
        let isValid = true;
        for (let index = 0; index < templateRows.length; index++) {
            const rowId = templateRows[index]?.id ?? templateRows[index].gridId;
            const element = document.querySelector('[data-id="' + rowId + '"]');

            if (element) {
                const question = templateRows[index];
                if (
                    validateRequiredCell(
                        element,
                        '.display-name',
                        question.displayName
                    )
                ) {
                    isValid = false;
                }
                if (
                    validateRequiredCell(
                        element,
                        '.display-file-format',
                        question.displayFileFormat
                    )
                ) {
                    isValid = false;
                }
                if (
                    validateRequiredCell(
                        element,
                        '.allowed-file-format',
                        question.allowedFileFormat
                    )
                ) {
                    isValid = false;
                }
                if (
                    validateRequiredCell(
                        element,
                        '.likely-source',
                        question.likelySource
                    )
                ) {
                    isValid = false;
                }
            }
        }

        if (
            !validateGridEditorRowDisplayOrder(
                templateRows,
                apiRef.current.state,
                EditorTypeEnum.Documents
            )
        ) {
            isValid = false;
        }

        if (
            !validateUniqueItemName(
                templateRows,
                apiRef.current.state,
                EditorTypeEnum.Documents
            )
        ) {
            isValid = false;
        }
        return isValid;
    };

    const mapAllowedFilesFormat = (fileFormat: string) =>
        displayFormatVariable?.options?.find((f) => f.name === fileFormat)
            ?.option ?? 'any';

    const updateRows = (
        newRows: (oldRows: DataFormQuestion[]) => DataFormQuestion[]
    ): void => {
        const result = newRows(templateRows).map((question) => ({
            ...question,
            allowedFileFormat: mapAllowedFilesFormat(
                question.displayFileFormat
            ),
        }));

        setTemplateRows(result);
    };

    useEffect(() => {
        validateAllRows();
    }, [templateRows]);

    return (
        <EditableTable
            editorType={EditorTypeEnum.Documents}
            columns={columns()}
            rows={templateRows}
            setRows={updateRows}
            isLoading={isLoading}
            fieldToFocus="displayOrder"
            ref={gridTableRef}
            gridApiRef={apiRef}
        />
    );
});

export default DocumentEditor;

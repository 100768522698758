import React, { Fragment, useEffect, useState } from 'react';
import { Col, Card, CardBody, CardHeader } from 'reactstrap';
import BootstrapTable from '../../../components/react-bootstrap-table-next/react-bootstrap-table2';
import filterFactory from '../../../components/react-bootstrap-table-next/react-bootstrap-table2-filter';
import paginationFactory from '../../../components/react-bootstrap-table-next/react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import InfoTooltip from '../../../components/info-tooltip/info-tooltip';
import GiftCard from '../../../components/GiftCard/GiftCard';
import createNotification from '../../../utils/createNotification';
import { useStateSelector } from '../../../store/selectors';
import moment from 'moment';
import Helpers from '../../../utils/helper';
import { OwnerTypeEnum } from '../../../wallet/models/OwnerTypeEnum';
import { Transaction } from '../../../wallet/models/Transaction';
import { TransactionPayment } from '../../../wallet/models/TransactionPayment';
import {
    GiftCardTransactionTypeIds,
    TransactionSource,
} from '../../../wallet/models/TransactionSource';

interface GiftCardDonationHistoryTableProps {
    ownerType: OwnerTypeEnum;
    walletId?: number;
    isAdminAccess?: boolean;
    colWidth?: number;
}

const GiftCardDonationHistoryTable = (
    props: GiftCardDonationHistoryTableProps
) => {
    const axios = useStateSelector((state) => state.core.axios);

    const [page, setPage] = useState(1);
    const [totalSize, setTotalSize] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(5);
    const [tableData, setTableData] = useState<Transaction[]>([]);

    useEffect(() => fetch(), []);

    const defaultSortDirection: [{ dataField: any; order: any }] = [
        {
            dataField: 'date',
            order: 'desc',
        },
    ];

    const getColumns = () => {
        return [
            { dataField: 'id', text: '', hidden: true },
            {
                dataField: 'date',
                text: 'Date',
                sort: true,
                formatter: (cellContent: Date) =>
                    moment(cellContent).format('MM/DD/YYYY, h:mm A'),
            },
            {
                dataField: 'type',
                text: 'Use Of Credit',
                formatter: (cellContent: number, row: any) => (
                    <>
                        {
                            TransactionSource.find((t) => t.id == cellContent)
                                .value
                        }{' '}
                        {row.reason ? <> - {row.reason}</> : <></>}
                        {row.type === 4 && row.comment ? (
                            <InfoTooltip
                                idText={`${row.id} ${row.date}`}
                                text={row.comment}></InfoTooltip>
                        ) : (
                            []
                        )}
                        {GiftCardTransactionTypeIds.includes(cellContent) ? (
                            <>
                                <span className="info-tooltip">
                                    <FontAwesomeIcon
                                        id={'gift-card-w-' + row.id}
                                        icon={faInfoCircle}
                                        className="tooltip-icon"
                                    />
                                </span>
                                <Tooltip
                                    anchorId={'gift-card-w-' + row.id}
                                    className="tooltip-content gift-card-tooltip"
                                    place="top">
                                    <GiftCard
                                        walletId={
                                            !!props.isAdminAccess
                                                ? props.walletId
                                                : undefined
                                        }
                                        giftCardTransactionId={
                                            row.id
                                        }></GiftCard>
                                </Tooltip>
                            </>
                        ) : (
                            []
                        )}
                    </>
                ),
                sort: true,
            },
            {
                dataField: 'recipient',
                text: 'Recieving Party',
                sort: true,
                formatter: (cellContent: string) =>
                    !cellContent ? '-' : cellContent,
            },
            {
                dataField: 'payments.amount',
                text: 'Amount',
                sort: true,
                style: {
                    width: '50px',
                },
                formatter: (_cellContent: any, row: Transaction) => {
                    const rewardCredits = row.payments.find(
                        (x: TransactionPayment) => x.type == 1
                    );
                    const storeCredits = row.payments.find(
                        (x: TransactionPayment) => x.type == 2
                    );
                    const flexCredits = row.payments.find(
                        (x: TransactionPayment) => x.type == 4
                    );
                    return (
                        <div
                            className={
                                rewardCredits && storeCredits && flexCredits
                                    ? 'total-credits money-cell'
                                    : 'money-cell'
                            }>
                            <div style={{ marginLeft: 0 }}>
                                {Helpers.currencyFormatWithoutBracket(
                                    rewardCredits?.amount ??
                                        0 + storeCredits?.amount ??
                                        0 + flexCredits?.amount ??
                                        0
                                )}
                            </div>
                        </div>
                    );
                },
            },
        ];
    };

    const fetch = () => {
        axios
            .post(
                `/api/wallet/transactions/giftcardsDonationHistory${
                    !!props.isAdminAccess ? '/adminAccess' : ''
                }`,
                {
                    shouldUseWalletBalance: true,
                    paginationOptions: {
                        pageSize: sizePerPage,
                        pageNumber: page == 0 ? 1 : page,
                    },
                    sortingOptions: {
                        isAscending: false,
                        field: 'date',
                    },
                    isPurchaseOnly: false,
                },
                {
                    params: {
                        walletId: props.walletId,
                        ownerType: props.ownerType,
                    },
                }
            )
            .then((response: any) => {
                if (
                    Helpers.isResponseSuccessful(
                        response,
                        'An error occured while fetching Rewards History data'
                    )
                ) {
                    setTableData(response.data.transactions);
                    setPage(response.data.pageNumber);
                    setSizePerPage(response.data.pageSize);
                    setTotalSize(response.data.totalTransactions);
                }
            })
            .catch((error: any) => {
                setTableData([]);
                createNotification(
                    'An error occured while fetching Rewards History data',
                    'error'
                );
            });
    };

    const handleTableChange = (type: any, newState: any) => {
        axios
            .post(
                `/api/wallet/transactions/giftcardsDonationHistory${
                    !!props.isAdminAccess ? '/adminAccess' : ''
                }`,
                {
                    shouldUseWalletBalance: true,
                    paginationOptions: {
                        pageSize: newState.sizePerPage,
                        pageNumber: newState.page == 0 ? 1 : newState.page,
                    },
                    sortingOptions: {
                        isAscending: newState.sortOrder === 'asc',
                        field: newState.sortField,
                    },
                    isPurchaseOnly: false,
                },
                {
                    params: {
                        walletId: props.walletId,
                        ownerType: props.ownerType,
                    },
                }
            )
            .then((response: any) => {
                if (
                    Helpers.isResponseSuccessful(
                        response,
                        'An error occured while fetching Rewards History data'
                    )
                ) {
                    setTableData(response.data.transactions);
                    setPage(response.data.pageNumber);
                    setSizePerPage(response.data.pageSize);
                    setTotalSize(response.data.totalTransactions);
                }
            })
            .catch(() => {
                createNotification(
                    'An error occured while fetching Rewards History data',
                    'error'
                );
            });
    };

    return (
        <Fragment>
            {tableData ? (
                <Col md={props.colWidth ?? 12}>
                    <Card className="card-section my-wallet main-card mb-3">
                        <CardHeader>
                            <div className="practice-heading">
                                Gift Card & Donation History
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <BootstrapTable
                                    bootstrap4
                                    remote
                                    keyField="id"
                                    data={tableData}
                                    columns={getColumns()}
                                    defaultSorted={defaultSortDirection}
                                    filter={filterFactory()}
                                    pagination={paginationFactory({
                                        page,
                                        sizePerPage,
                                        totalSize,
                                    })}
                                    onTableChange={handleTableChange}
                                    noDataIndication="No Data available"
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            ) : (
                []
            )}
        </Fragment>
    );
};

export default GiftCardDonationHistoryTable;

import React, { useState } from 'react';
import './DataTableStagesHost.scss';
import {
    DataRequestProject,
    DataRequestProjectState,
} from '../../../models/DataRequestHub/DataRequestProject';
import DataTableStagesList from '../DataTableStagesList/DataTableStagesList';
import { useNavigate } from 'react-router-dom';
import {
    DataTableEditorModeEnum,
    EditorModeEnum,
    EditorTypeEnum,
} from '../../Admin/DataRequest/ProjectEditorHost/ProjectEditorHost';
import { DataTableForm } from '../DataTableForm/DataTableForm';
import { DesignModeSwitcher } from '../DesignModeSwitcher/DesignModeSwitcher';

interface DataTableStagesHostProps {
    projects: DataRequestProject[];
    isAdminView: boolean;
    updateProjectsData(isInitialFetch?: boolean): void;
    preselectedTableId: number;
    preselectedTableViewId: number;
}

const DataTableStagesHost = (props: DataTableStagesHostProps) => {
    const navigate = useNavigate();
    const [selectedDataTableId, setSelectedDataTableId] = useState<number>(
        props.preselectedTableId
    );
    const [selectedTableViewId, setSelectedTableViewId] = useState<number>(
        props.preselectedTableViewId
    );

    const enableDesignMode = (viewId: number) => {
        const targetProject = getProjectByViewId(viewId);

        if (targetProject) {
            navigate('/project-editor', {
                state: {
                    dataTableEditorParams: {
                        targetTableId: selectedDataTableId,
                        targetTableViewId: selectedTableViewId,
                    },
                    dataTableEditorMode: selectedTableViewId ? DataTableEditorModeEnum.EditView : DataTableEditorModeEnum.Table,
                    project: targetProject,
                    shouldNavigateToProjectViewMode: true,
                    type: EditorTypeEnum.DataTable,
                    mode: EditorModeEnum.EditProject,
                    additionalUrlToReturn: `?tab=${
                        EditorTypeEnum.DataTable + 1
                    }`,
                },
            });
        }
    };

    const getProjectByViewId = (viewId: number) =>
        props.projects.find((project) =>
            project.dataTables.some((table) =>
                table.views.some((view) => view.id === viewId)
            )
        ) ?? props.projects.find((project) => project);

    const getDataTable = (tableId: number) => {
        const result = props.projects
            .flatMap((x) => x.dataTables)
            .find((f) => f.id === tableId);

        return result;
    };

    const getTableView = (viewId: number) => {
        const allDataTables = props.projects.flatMap((x) => x.dataTables);
        const allViews = allDataTables.flatMap((x) => x.views);
        const result = allViews.find((x) => x.id === viewId);
        return result;
    };

    const renderStagesList = () => (
        <DataTableStagesList
            onTableViewNameClick={(tableId: number, viewId: number) => {
                turnOnTableView(tableId, viewId);
            }}
            onTableNameClick={(tableId) => {
                turnOnTableView(tableId);
            }}
            enableDesignMode={enableDesignMode}
            isEditorMode={false}
            isTemplateEditorView={false}
            invalidTableStageIds={[]}
            invalidTableViewIds={[]}
            {...props}
        />
    );

    const turnOnTableView = (tableId: number, selectedViewId?: number) => {
        setSelectedDataTableId(tableId);
        setSelectedTableViewId(selectedViewId);
    };

    const turnOnStagesList = () => {
        setSelectedDataTableId(null);
        setSelectedTableViewId(null);
    };

    const isProjectActive = (tableId: number) => {
        const targetProject =
            props.projects.find((project) =>
                project.dataTables.some((table) => table.id === tableId)
            ) ?? props.projects.find((project) => project);

        return targetProject.state === DataRequestProjectState.Active;
    };

    const renderView = () => {
        const targetProject = getProjectByViewId(selectedTableViewId);
        const targetTable = getDataTable(selectedDataTableId);
        const targetView = getTableView(selectedTableViewId);

        return (
            <>
                <div className="data-table-stages-host-header">
                    <div className="stage-navigation-breadcrumb">
                        <label
                            className="link-text"
                            onClick={() => turnOnStagesList()}>
                            Data Tables
                        </label>
                        <label className="node-separator">{' / '}</label>
                        <label
                            className="link-text"
                            onClick={() => turnOnStagesList()}>
                            {targetTable.name}
                        </label>
                        {selectedTableViewId ? (
                            <>
                                <label className="node-separator">
                                    {' / '}
                                </label>
                                <label className="link-text">
                                    {targetView.name}
                                </label>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    {props.isAdminView && isProjectActive(targetTable.id) && (
                        <DesignModeSwitcher
                            defaultValue={false}
                            onEnable={enableDesignMode}
                            formId={0}></DesignModeSwitcher>
                    )}
                </div>

                <DataTableForm
                    isAdminView={props.isAdminView}
                    projectId={targetProject.id}
                    variables={targetProject.variables}
                    dataTable={targetTable}
                    selectedTableViewId={selectedTableViewId}
                    updateProjectsData={props.updateProjectsData}
                />
            </>
        );
    };

    const render = () => {
        return selectedDataTableId || selectedTableViewId
            ? renderView()
            : renderStagesList();
    };

    return <div className="data-table-stages-host">{render()}</div>;
};

export default DataTableStagesHost;
